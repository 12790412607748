import * as urls from '@/config/net'

const defaultGoodsImg = 'http://img.laoban100.com/weblink/201712/pic-goods-default.png!mini'
const imgFullPath = function (picUrl) {
  return fullPath(urls.imgUrl, picUrl)
}

const imgCDNPath = function (picUrl) {
  return fullPath(urls.imgUrl, picUrl)
}

function fullPath (baseUrl, picUrl) {
  if (!picUrl) return ''
  return isFullPath(picUrl) ? picUrl : correctPath(baseUrl, picUrl)
}

// 检查是否是完整地址
function isFullPath (picUrl) {
  return /htt(p|ps):\/\/|tencent.shengyi.ai/.test(picUrl)
}

// 返回完整图片地址
function correctPath (baseUrl, picUrl) {
  baseUrl = baseUrl.replace(/\/$/, '')
  picUrl = picUrl.replace(/^\//, '')
  return `${baseUrl}/${picUrl}`
}

/*
 * 将传入的图片按照url和type类型就行处理
 * params (picUrl="图片url",type="处理图片类型")
*/
const imgJudgeUrl = function (picUrl, type = 'small') {
  if (picUrl.indexOf('!') > -1) {
    picUrl = picUrl.substring(0, picUrl.indexOf('!')) // 将!后面部分剔除
    picUrl = (picUrl + '!' + type)
    return picUrl
  } else {
    picUrl = (picUrl + '!' + type)
    return picUrl
  }
}

/**
 * 补全img的路由并且加上后缀包含imgJudgeurl函数
 * 用这个方法就可以
 */
const imgFullFix = function (picUrl, type = 'small') {
  debugger
  if (!picUrl) return
  picUrl = imgCDNPath(picUrl)
  // if (picUrl[picUrl.indexOf('n') + 1] !== '/') picUrl = picUrl.slice(0, picUrl.indexOf('n') + 1) + '/' + picUrl.slice(picUrl.indexOf('n') + 1)
  return imgJudgeUrl(picUrl, type)
}

export { imgFullPath, imgCDNPath, defaultGoodsImg, imgJudgeUrl, imgFullFix }
// eslint-disable-next-line no-multiple-empty-lines

