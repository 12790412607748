
<script>
/* eslint-disable no-unused-vars */
const VConsole = require('vconsole')
export default {
  name: 'App',
  data () {
    return {
      transitionName: 'van-fade'
    }
  },
  render () {
    const { $route: { meta } } = this
    const inKeep = (
      <keep-alive>
        <router-view />
      </keep-alive>
    )
    const notKeep = (
      <router-view />
    )
    return (
      <div>
        <transition name={this.transitionName}>
          {meta.keeAlive ? inKeep : notKeep}
        </transition>
      </div>
    )
  },
  async  mounted () {
    if (process.env.NODE_ENV === 'testing' || process.env.NODE_ENV === 'development') {
      var vConsole = new VConsole()
    }
    await this.$store.dispatch('userInfo/getBaseInfo')
  },
  methods: {

  },
  computed: {
    isDevelop () {
      return (process.env.NODE_ENV === 'testing' || process.env.NODE_ENV === 'development')
    }
  },
  watch: {
    '$route' (to, from) {
      if (from.fullPath === '/') {
        this.transitionName = 'van-fade'
        return
      }
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'fold-right' : 'fold-left'
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
