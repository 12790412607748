<template>
  <page-view style="background-color:#faf7fa;">
    <!-- <c-page class="goods-containner"> -->
    <template v-slot:nav>
      <nav-bar title="浏览记录" left-arrow></nav-bar>
    </template>
    <scroll-view height="fullView" ref="scroll" pullup style="background-color:#faf7fa;">
      <div class="cv-box">
        <div class="cv-box__header">
          <div>
            <avatar shape="square" class="header__avatar" :avatarImage="imgFullFix(memberDetailInfo.portrait, 'small')" :avatarName="memberDetailInfo.name" />
            <!-- <img :src="imgFullFix(memberDetailInfo.portrait)" class="header__avatar" /> -->
          </div>
          <div class="header__info">
            <span class="userinfo">
              <span class="username">
                <span style="float:left;">{{ memberDetailInfo.name }}</span>
                <van-tag round class="discount">{{ memberDetailInfo.discount }}折</van-tag>
              </span>
              <span class="usercardid">{{ memberDetailInfo.memberRankName }} | 卡号：{{ memberDetailInfo.memberNo }}</span>
            </span>
          </div>
          <div class="header__btn">
            <span class="userdetailbtn">基本信息<i class="iconfont2 icon--rightarrow rightarrow"></i></span>
          </div>
        </div>
        <div class="cv-box__allinfo">
          <span>共23条浏览记录</span>
          <span>预计可成交3单</span>
        </div>

        <div class="cv-box__list">
          <div class="item van-hairline--bottom">
            <span class="avatar"></span>
            <span class="goods-name">依能苏打水 青柠味 无糖</span>
            <span class="times">13次</span>
            <span class="time">2020-02-19</span>
          </div>
          <div class="item van-hairline--bottom">
            <span class="avatar"></span>
            <span class="goods-name">依能苏打水 青柠味 无糖</span>
            <span class="times">13次</span>
            <span class="time">2020-02-19</span>
          </div>
          <div class="item van-hairline--bottom">
            <span class="avatar"></span>
            <span class="goods-name">依能苏打水 青柠味 无糖</span>
            <span class="times">13次</span>
            <span class="time">2020-02-19</span>
          </div>
          <div class="item van-hairline--bottom">
            <span class="avatar"></span>
            <span class="goods-name">依能苏打水 青柠味 无糖</span>
            <span class="times">13次</span>
            <span class="time">2020-02-19</span>
          </div>
        </div>

        <div class="cv-box__bottom">
          <span style="color:#999999;" class="tips"><span style="color:#FF4949;">* </span>预览10次以上的商品，一键发送短信提醒</span>
          <van-button color="#FF6A3C" block class="message-notice" @click="openNotice">短信提醒</van-button>
        </div>
      </div>
    </scroll-view>
    <van-dialog v-model="noticeModel" show-cancel-button confirmButtonColor="#FF6A3C" className="notice-dialog">
      <div class="notice-area">
        <van-field
          v-model="message"
          rows="2"
          autosize
          type="textarea"
          maxlength="50"
          placeholder=""
          show-word-limit
        />
      </div>
    </van-dialog>
    <!-- </c-page> -->
  </page-view>
</template>
<style lang="scss" scoped>
@import './index.scss';
</style>
<script>
import PageView from '@/layouts/PageView'
import Avatar from '@/components/avatar'
import NavBar from '@/components/nav-bar'
import { Dialog, Field, Button } from 'vant'
import { imgFullFix } from '@/utils/img'
import ScrollView from '@/components/scroll-view'
export default {
  data () {
    return {
      memberDetailInfo: {},
      noticeModel: false,
      message: '尊敬的XX先生/女士，您在化妆精品店心仪的商品等你很久了～快来带走它吧！'
    }
  },
  components: { Avatar, ScrollView, NavBar, PageView, [Dialog.Component.name]: Dialog.Component, [Field.name]: Field, [Button.name]: Button },
  created () {
  },
  mounted () {
    this.memberDetailInfo = this.$route.query
  },
  beforeDestroy () {
  },
  methods: {
    imgFullFix,
    openNotice () {
      this.noticeModel = true
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
</style>
