<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar title="权限配置" right-text="完成" @click-right="goBack()" />
    </template>
    <div class="content">
      <van-cell-group>
        <template v-for="(configItem) in pConfig">
          <van-cell :key="configItem.id" :title="configItem.displayName">
            <template #right-icon>
              <van-switch v-model="configItem.value" @change.self="switchChange(configItem)" active-color="#ff6a3c" size="20" />
            </template>
          </van-cell>
          <template v-if="configItem.value">
            <van-cell-group :key="configItem.key" v-if="configItem.value">
              <van-cell
                clickable
                v-for="(childItem) in configItem.children"
                :key="childItem.id"
              >
                <template #title>
                  <van-checkbox style="display:inline-block;margin-right:8px;vertical-align: middle;"
                                :class="{'check-box__not-full': childItem.notAll}"
                                :name="childItem.id"
                                checked-color="#ff6a3c"
                                @click.stop="e => {}"
                                @change="checkBoxChange(childItem)"
                                v-model="childItem.value"
                  />
                  <span class="custom-title">{{ childItem.displayName }}</span>
                </template>
                <template #right-icon v-if="actionSheetCanShow(childItem)">
                  <van-icon class="van-cell__right-icon" color="#969799" @click="showActionSheet(childItem)" name="arrow"></van-icon>
                </template>
              </van-cell>
            </van-cell-group>
          </template>
        </template>
      </van-cell-group>
    </div>
    <!-- action-sheet -->
    <template v-slot:other>
      <van-popup position="bottom" :style="{ minHeight: '30%' }" v-model="actionSheet">
        <div class="van-hairline--top-bottom van-picker__toolbar">
          <button type="button" class="van-picker__cancel" @click.stop="cancel">取消</button>
          <div class="van-ellipsis van-picker__title">{{ actionSheetData.title }}</div>
          <button type="button" class="van-picker__confirm" @click.stop="ensure">确认</button>
        </div>
        <van-cell-group>
          <van-cell :key="item.tempValue + item.displayName" clickable @click="item.tempValue = !item.tempValue" v-for="(item) in actionSheetData.list">
            <template #title>
              <van-checkbox style="display:inline-block;margin-right:8px;vertical-align: middle;"
                            checked-color="#ff6a3c"
                            @click.stop="e => {}"
                            v-model="item.tempValue"
              />
              <span class="custom-title">{{ item.displayName }}</span>
            </template>
          </van-cell>
        </van-cell-group>
      </van-popup>
    </template>
  </page-view>
</template>
<style lang="scss" scoped>
  @import './index.scss';
</style>
<script>
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import { Cell, CellGroup, Toast, Switch, Popup, Checkbox, Icon } from 'vant'
import AddPosition from '@/model/permission/add-position/form.js'
import AddStaff from '@/model/permission/add-staff/form.js'

import { mapGetters } from 'vuex'

export default {
  name: 'PermissionConfig',
  data () {
    return {
      bodyCheckType: ['head', 'body'], // 当point为true，选择区域限head，body
      headCheckType: ['head'], // 当point为true，选择区域只限head
      actionSheet: false,
      actionSheetData: {},
      queryObj: {},
      addStaffIns: null
    }
  },
  components: {
    PageView,
    NavBar,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Switch.name]: Switch,
    [Popup.name]: Popup,
    [Checkbox.name]: Checkbox
  },
  created () {
  },
  mounted () {
    this.queryObj = this.$route.query
    if ('positionId' in this.queryObj) {
      this.$store.dispatch('permission/cleanPermissionConfig')
      this.$store.dispatch('permission/loadPermissionConfig', { positionId: this.queryObj.positionId })
    }
    if ('userId' in this.queryObj) {
      this.$store.dispatch('permission/cleanPermissionConfig')
      this.$store.dispatch('permission/loadPermissionConfig').then(() => {
        AddStaff.loadUserPermissionsIds(this.queryObj.userId).then(data => {
          this.configInstance._initValue(data.permissionIds)
          this.configInstance._initComputeNotAll()
          this.addStaffIns = new AddStaff()
          this.addStaffIns.formData.positionId = data.positionId
        })
      })
    }
  },
  beforeDestroy () {
  },
  methods: {
    actionSheetCanShow (value) {
      return Object.keys(value.children).length !== 0
    },
    showActionSheet (items) {
      if (this.actionSheetCanShow(items)) {
        const list = []
        const parents = [] // 第三层数据
        const thirdChildren = Object.values(items.children || {})
        for (const item of thirdChildren) {
          const finalChildren = Object.values(item.children || {})
          finalChildren.length ? parents.push(item) : list.push(item) // 有孩子放入parents，无孩子直接放入list
          for (const cItem of finalChildren) {
            cItem.displayName = item.displayName + cItem.oriName
            list.push(cItem)
          }
        }
        // 维护tempValue从而实现取消和确定
        for (const listItem of list) {
          this.$set(listItem, 'tempValue', listItem.value)
        }
        this.actionSheet = true // 显示actionSheet
        this.actionSheetData = {
          title: items.displayName,
          oriItem: items,
          parents,
          list
        }
      } else {
        items.value = !items.value
      }
    },
    switchChange (item) {
      if (!item.value) this.changeAllChildren(item.children, false)
      if (!window.$bridge) return
      window.$bridge.message.awakenVoice()
    },
    checkBoxChange (item) {
      this.changeAllChildren(item.children || {}, item.value)
      delete item.notAll // 移除notAll
    },
    changeAllChildren (children, value) {
      // 递归设置所有孩子的value值
      if (!Object.keys(children).length) return
      const items = Object.values(children)
      for (const item of items) {
        item.value = value
        this.changeAllChildren(item.children || {}, value)
      }
    },
    cancel () {
      this.actionSheet = false
    },
    ensure () {
      this.actionSheet = false
      // 将tempValue赋值给value
      this.actionSheetData.list.forEach(item => {
        item.value = item.tempValue
      })
      this.actionSheetChange()
    },
    actionSheetChange () {
      // 计算全选和全未选
      let allSelect = true
      let allUnselect = false
      // 计算全选和全未选
      this.actionSheetData.list.forEach(item => {
        allSelect = allSelect && item.value
        allUnselect = allUnselect || item.value
      })
      // 设置notAll的值
      this.$set(this.actionSheetData.oriItem, 'notAll', !allSelect)
      // 如果都未选，将oriItem设置为false
      this.actionSheetData.oriItem.value = allUnselect
      // 计算第四层是否全选，维护第三层状态
      this.actionSheetData.parents.forEach(item => {
        item.value = false // 将parent数据设置为false，下面做或运算只有要一个真那么就是真
        Object.values(item.children || {}).forEach(child => {
          item.value = item.value || child.value
        })
      })
    },
    async goBack () {
      if ('positionId' in this.queryObj) {
        const permissionIds = await this.$store.dispatch('permission/permissionIds')
        const displayName = this.configInstance.displayName
        AddPosition.updatePermission(this.queryObj.positionId, displayName, permissionIds).then(isOk => {
          isOk ? this.saveSuccess() : Toast('保存失败')
        })
        return
      }
      if ('userId' in this.queryObj) {
        if (!this.addStaffIns) return
        const permissionIds = await this.$store.dispatch('permission/permissionIds')
        this.addStaffIns.formData.permissionIds = permissionIds
        this.addStaffIns.updatePermission(this.queryObj.userId).then(res => {
          res.code === 0 ? this.saveSuccess() : Toast('保存失败')
        })
        return
      }
      this.$router.back() // 返回
    },
    saveSuccess () {
      Toast('保存成功')
      setTimeout(() => {
          $bridge.navigation.popViewController() // eslint-disable-line
      }, 500)
    }
  },
  computed: {
    ...mapGetters({
      pConfig: 'permission/getPermissionConfig',
      configInstance: 'permission/getConfigInstance'
    })
  },
  watch: {}
}
</script>
