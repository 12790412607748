<template>
  <div class="nav-bar" :class="[border ? 'van-hairline--bottom' : '']" :style="styles">
    <div v-if="$slots.left || leftArrow || leftText" class="nav-bar__left" @click="handleLeftClick">
      <slot v-if="$slots.left" name="left"></slot>
      <template v-else>
        <i class="iconfont2 icon-jiantou translate-fanzhuan"></i>
        <!-- <van-icon v-if="leftArrow" name="icon-jiantou" /> -->
        <span v-if="leftText" class="nav-bar__text">{{ leftText }}</span>
      </template>
    </div>
    <div class="nav-bar__title van-ellipsis">
      <slot v-if="$slots.title" name="title"></slot>
      <template v-else>{{ title }}</template>
    </div>
    <div v-if="$slots.right || rightText" class="nav-bar__right" @click="handleRgithBtnClick">
      <slot v-if="$slots.right" name="right"></slot>
      <template v-else>
        <span v-if="rightText" class="nav-bar__text">{{ rightText }}</span>
      </template>
    </div>
  </div>
</template>
<script>
import { Icon } from 'vant'
import device from '@/tools/device'
export default {
  name: 'NavBar',
  mixins: [],
  components: { [Icon.name]: Icon },
  props: {
    title: {
      type: String,
      default: '生意专家'
    },
    leftText: {
      type: String,
      default: ''
    },
    rightText: {
      type: String,
      default: ''
    },
    rightImageName: {
      type: String,
      default: ''
    }, // 右侧设置图片
    leftArrow: {
      type: Boolean,
      default: true
    },
    border: Boolean,
    zIndex: {
      type: [Number, String],
      default: 1
    }
  },
  data () {
    return {
      currentPath: null
    }
  },
  computed: {
    styles () {
      return { zIndex: this.zIndex }
    },
    routerPath () {
      return this.$route.path
    }
  },
  watch: {
    routerPath (val) {
      // 当路径再次变化为自己时，再次执行setNavigation
      if (val === this.currentPath) this.setNavigation()
    },
    rightImageName (val) {
      if (val) { // 如果动态设置了值
        window.$bridge && window.$bridge.navigation.setRightButton('image', this.rightImageName, 'muiNavbarRightBtnClick();')
      }
    }
  },
  created () { },
  mounted () {
    this.currentPath = this.$route.path
    if (device.ios) {
      // ios设置navbar的事件
      window.muiNavbarGoBack = this.goBack.bind(this)
      window.muiNavbarRightBtnClick = this.handleRgithBtnClick.bind(this)
      this.setNavigation()
    }
  },
  destroyed () { },
  methods: {
    handleLeftClick (e) {
      this.goBack()
      this.$emit('click-left', e)
    },
    goBack () {
      const nowPath = this.$route.path
      if (nowPath === this.$cRouter.path) { // push方式
        if (!window.$bridge) return
          $bridge.navigation.popViewController() // eslint-disable-line
      } else {
        if (window.history.length === 1) { // replace情况
          window.$bridge && $bridge.navigation.popViewController() // eslint-disable-line
        }
        // replace 方式调用自身的返回
        this.$router.back()
      }
    },
    setNavigation () {
      if (!window.$bridge) return
        $bridge.navigation.setTitle(this.title) // eslint-disable-line
        $bridge.navigation.setBackAction('javascript:muiNavbarGoBack()') // eslint-disable-line
      if (this.rightText) {
        window.$bridge.navigation.setRightButton('normal', this.rightText, 'muiNavbarRightBtnClick();')
      } else if (this.rightImageName) {
        window.$bridge.navigation.setRightButton('image', this.rightImageName, 'muiNavbarRightBtnClick();')
      } else {
        window.$bridge.navigation.removeRightButton()
      }
    },
    handleRgithBtnClick (e) {
      this.$emit('click-right', e)
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
