import debounce from 'lodash.debounce'
import Vue from 'vue'

Vue.directive('square', {
  inserted (el) {
    /* eslint-disable */
    $(el).height(($(el).width() * 2) / 75 + 'rem')
  }
})

Vue.directive('viewFixed', {
  update (el) {
    if ($(el).css('display') === 'none') {
      $('.c-content').css({overflow: 'auto'})
    } else {
      $('.c-content').css({overflow: 'hidden'})
    }
  }
})


Vue.directive('scrollLoad', {
  bind (el, binding, vnode) {
    el['scrollLoadCTX'] = {
      vm: vnode.context,
      scrollListener: scrollListener
    }
    let scrollListener = debounce(function () {
      let top = el.scrollTop
      let height = el.clientHeight
      let scrollHeight = el.scrollHeight
      let nowHeight = height + top
      if (nowHeight >= scrollHeight - 10) {
        vnode.context.scrollLoadEvent()
      }
    }, 100)
    el.addEventListener('scroll', scrollListener)
    el.addEventListener('touchmove', scrollListener)

  },
  unbind (el) {
    el.removeEventListener('scroll', el['scrollLoadCTX'].scrollListener)
    el.removeEventListener('touchmove', el['scrollLoadCTX'].scrollListener)
  }
})
