import { apiUrl } from '@/config/net'
import api from '@/tools/api-tools'
import Vue from 'vue'

class PermissionConfig {
  constructor () {
    this.gMap = {} // 树形结构
    this.gMapIndex = {} // 树形结构节点索引
    this.displayName = ''
  }

  loadPositionPermissions (positionId) {
    return Promise.all([
      this._permissions(),
      this._rolePosition(positionId)
    ]).then(values => {
      const permissions = values[0]
      const haveSetPermissions = values[1]
      this._dataProcess(permissions)
      this._initValue(haveSetPermissions)
      this._initComputeNotAll()
      return {
        permissions: this.gMap
      }
    })
  }

  loadPermissions () {
    return this._permissions().then(data => {
      this._dataProcess(data)
      return {
        permissions: this.gMap
      }
    })
  }

  _permissions () {
    return api.bget(`https:${apiUrl}/mobile/security/permissions`).then(res => {
      const data = res.data.data || []
      return data
    })
  }

  // 角色权限
  _rolePosition (positionId) {
    return api.bget(`https:${apiUrl}/mobile/security/user-position/${positionId}`).then(res => {
      const data = res.data.data || {}
      this.displayName = data.displayName
      const positions = data.permissionIds || []
      return positions
    })
  }

  _dataProcess (data) {
    for (const item of data) {
      const deeps = item.name.split('.')
      this._deepSearch(this.gMap, deeps, item)
    }
  }

  _deepSearch (map, deeps, item) {
    deeps.forEach((deep, index) => {
      if (!map[deep]) {
        const nodeObj = {
          item: item,
          deepIndex: index,
          id: item.id,
          key: item.name, // 层级串
          value: false, // 是否设置，默认是否
          oriName: item.displayName, // 用于拼接，防止污染
          displayName: item.displayName,
          children: {}
        }
        Vue.set(map, deep, nodeObj)
        this.gMapIndex[item.id] = nodeObj
      } else {
        map = map[deep].children
      }
    })
  }

  _initValue (data) {
    for (const item of data) {
      if (this.gMapIndex[item]) this.gMapIndex[item].value = true
    }
  }

  // 只处理deep2的节点，计算是否全选
  _initComputeNotAll () {
    const allNode = Object.values(this.gMapIndex)
    for (const item of allNode) {
      if (item.deepIndex === 1) this._computeNotAll(item)
    }
  }

  // 计算是否全选
  _computeNotAll (itemObj) {
    let allSelect = true
    // 计算全选和全未选
    const childrenList = Object.values(itemObj.children)
    childrenList.forEach(item => {
      allSelect = allSelect && item.value
      Object.values(item.children || {}).forEach(child => {
        allSelect = allSelect && child.value
      })
    })
    Vue.set(itemObj, 'notAll', !allSelect)
  }

  permissionIds () {
    const values = Object.values(this.gMapIndex)
    const trueValues = values.filter(item => {
      return item.value
    })
    return trueValues.map(item => {
      return item.id
    })
  }
}

export default PermissionConfig
