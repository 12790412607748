// 会员浏览
import MemberCv from '@/views/member-detail/cv-history/index'
// 会员详情
import MemberDetail from '@/views/member-detail/index'
// 会员消息
import MemberMessage from '@/views/member-detail/message/index'

// 微信会员卡模块
export default [
  {
    path: '/wechat-card',
    component: () => import('@/views/wechat-card'),
    meta: {
      keeAlive: true
    }
  },
  {
    path: '/recommend/index',
    component: () => import('@/views/recommend'),
    meta: {
      keeAlive: true
    }
  },
  {
    path: '/recommend/detail',
    component: () => import('@/views/recommend/_cell'),
    meta: {
      keeAlive: true
    }
  },
  {
    name: 'wxcardSmSInvite',
    path: '/wxcard-sms-invite',
    component: () => import('@/views/wxcard-sms-invite')
  },
  {
    name: 'personCenter',
    path: '/person-center',
    component: () => import('@/views/person-center')
  },
  {
    path: '/wx-member-list',
    component: () => import('@/views/wx-member-list')
  },
  {
    path: '/wx-consumption',
    component: () => import('@/views/wx-consumption')
  },
  {
    path: '/member-detail',
    component: MemberDetail,
    meta: {
      index: 1
    }
  },
  {
    path: '/member-message',
    component: MemberMessage,
    meta: {
      index: 1
    }
  },
  {
    path: '/member-cv',
    component: MemberCv,
    meta: {
      index: 1
    }
  }
]
