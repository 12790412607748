import Vue from 'vue'
import Vuex from 'vuex'
import permission from './module/permission'
import setting from './module/setting'
import userInfo from './module/user-info'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    permission,
    userInfo,
    setting
  },
  state: {},
  getters: {},
  actions: {},
  mutations: {}
})

export default store
