import { mscUrl } from '@/config/net'
import api from '@/tools/api-tools'

export default class AttestMiniProgtamModel {
  /**
   * 提交快速注册小程序审核信息
   * @param {Object} params 提交的注册信息
   */
  static submitRegisterInfo (params = { name: '', code: '', code_type: 1, legal_persona_wechat: '', legal_persona_name: '' }) {
    return api.post(`${mscUrl}/miniapp-fastregister/summit-registerinfo`, params).then(res => res.data)
  }

  /**
   * 获取小程序的注册状态
   */
  static getRegisterStatus () {
    return api.get(`${mscUrl}/miniapp-fastregister/state`).then(res => res.data)
  }

  /**
   * 获取小程序的设置信息
   */
  static getSettingInfo () {
    return api.get(`${mscUrl}/miniapp-fastregister/fastregister-setting`).then(res => res.data)
  }

  /**
   * 编辑小程序名称
   */
  static submitName (params = { MiniAppName: '', IdCardUrl: '', IdCardMediaId: '', LicenseUrl: '', LicenseMediaId: '' }) {
    return api.post(`${mscUrl}/miniapp-fastregister/summit-miniappname`, params).then(res => res.data)
  }

  /**
   * 查询名称设置的状态
   */
  static checkNameState () {
    return api.get(`${mscUrl}/miniapp-fastregister/query-setnickname-state`).then(res => res.data)
  }

  /**
   * 验证小程序名称
   */
  static checkInputName (params) {
    return api.post(`${mscUrl}/miniapp-fastregister/verify-miniappname?miniAppName=${params}`).then(res => res.data)
  }

  /**
   * 初始化快速注册小程序状态
   */
  // static initState () {
  //   return api.post(`${mscUrl}/miniapp-fastregister/init-state`).then(res => res.data)
  // }
  /**
   * 上传图片资源到微信
   */
  static imgTomediaId (params = { MediaUrl: '' }) {
    return api.post(`${mscUrl}/miniapp-fastregister/upload-temporary-media`, params).then(res => res.data)
  }

  /**
   * 获取所有可设置的分类
   */
  static getAllcatagory () {
    return api.get(`${mscUrl}/miniapp-fastregister/allcategories`).then(res => res.data)
  }

  /**
   * 获取已设置过的类目
   */
  static getAlreadycatagory () {
    return api.get(`${mscUrl}/miniapp-fastregister/setted-categories`).then(res => res.data)
  }

  /**
   * 提交隐私设置
   */
  static setSearchstate () {
    return api.post(`${mscUrl}/miniapp-fastregister/summit-miniapp-searchstate`).then(res => res.data)
  }

  /**
   * 提交logo
   */
  static setLogo (params) {
    return api.post(`${mscUrl}/miniapp-fastregister/summit-miniapplogo`, params).then(res => res.data)
  }
}
