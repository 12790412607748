<template>
  <div>
    <van-divider v-show="isFinished">没有更多了</van-divider>
    <div v-show="!isFinished" style="text-align:center;padding:10px 0;">
      <van-loading color="#ff6a3c" size="20px">加载中...</van-loading>
    </div>
  </div>
</template>
<script>
import { Divider, Loading } from 'vant'
export default {
  name: 'ScrollViewLoading',
  mixins: [],
  components: { [Divider.name]: Divider, [Loading.name]: Loading },
  props: {
    isFinished: Boolean
  },
  data () {
    return {
    }
  },
  computed: {},
  watch: {},
  created () { },
  mounted () { },
  destroyed () { },
  methods: {}
}
</script>
<style lang="less" scoped>
</style>
