// 移动收款进件模块
export default [
  {
    path: '/acquire-intro/progress',
    component: () => import('@/views/acquire-intro/progress'),
    children: [
      {
        path: 'upload-info',
        component: () => import('@/views/acquire-intro/upload-info')
      }
    ]
  },
  {
    path: '/get-QrCode', // 物料领取页面
    component: () => import('@/views/get-QrCode'),
    children: []
  }
]
