import moment from 'moment'
import Vue from 'vue'

Vue.filter('YearMonth', (value, format = 'YYYY/MM') => {
  if (value) {
    return moment(value).format(format)
  } else {
    return null
  }
})
Vue.filter('date', (value, format = 'YYYY/MM/DD') => {
  if (value) {
    return moment(value).format(format)
  } else {
    return null
  }
})

Vue.filter('MonthDay', (value, format = 'MM/DD') => {
  if (value) {
    return moment(value).format(format)
  } else {
    return null
  }
})

Vue.filter('DateTime', (value, format = 'YYYY/MM/DD HH:mm:ss') => {
  if (value) {
    return moment(value).format(format)
  } else {
    return null
  }
})
Vue.filter('HourMinute', (value, format = 'HH:mm') => {
  if (value) {
    return moment(value).format(format)
  } else {
    return null
  }
})
