<template>
  <div class="steps">
    <div class="steps__items">
      <div :class="computedClass(0, active)" class="step">
        <div class="step__line">
        </div>
        <div class="step__icon"><span>1</span></div>
        <div class="step__title">小程序认证</div>
      </div>
      <div :class="computedClass(1, active)" class="step">
        <div class="step__line">
        </div>
        <div class="step__icon"><span>2</span></div>
        <div class="step__title">微信审核</div>
      </div>
      <div :class="computedClass(2, active)" class="step">
        <div class="step__line">
        </div>
        <div class="step__icon"><span>3</span></div>
        <div class="step__title">认证成功</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Stepper',
  mixins: [],
  components: {},
  props: {
    active: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
    }
  },
  computed: {},
  watch: {},
  created () { },
  mounted () { },
  destroyed () { },
  methods: {
    computedClass (current, active) {
      let targetClass = ''
      if (current === active) {
        targetClass += 'step--active'
      } else if (active > current) {
        targetClass += 'step--active step--finish'
      }
      return targetClass
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../../src/assets/scss/base/_mixin";
@import "../../../../src/assets/scss/base/config";
$wechatColor:#17BF62;

.steps {
  padding: px2rem(38px) px2rem(25px) 0;
  &__items {
    display: flex;
  }
}

.step {
  position: relative;
  flex:1;
  font-size: px2rem(24px);
  &:last-child {
    flex: none;
    >.step__line::after {
      display: none;
    }
  }
  &__icon {
    position: relative;
    margin-left: px2rem(35px);
    text-align: center;
    color:#999999;
    width: px2rem(49px);
    height: px2rem(49px);
    line-height: px2rem(49px);
    background-color:#E5E5E5;
    border-radius: 50%;
    z-index: 2;
    >span {
      position: relative;
      z-index: 10;
    }
  }
  &__line {
    position: absolute;
    left: 0;
    width: 100%;
    margin: 0 0 0 px2rem(40px);
    z-index: 1;
    &::after{
      content: '';
      display: inline-block;
      width: 100%;
      height:px2rem(4px);
      background:rgba(229,229,229,1);
      z-index: 1;
    }
  }
  &__title {
    width: px2rem(120px);
    text-align: center;
    margin-top:px2rem(23px);
    color:#999999;
  }

  &--active {
    >.step__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: px2rem(-20px);
      margin-left: px2rem(25px);
      font-size:px2rem(40px);
      font-weight:600;
      width: px2rem(80px);
      height: px2rem(80px);
      line-height: px2rem(80px);
      background: rgba(23,191,98,.3);
      color:#fff;
      z-index: 2;
      >span {
        overflow: hidden;
        display: inline-block;
        border-radius: 50%;
        width: px2rem(65px);
        height: px2rem(65px);
        line-height: px2rem(65px);
        background-color: $wechatColor;
      }
    }

    .step__title {
      margin-top: px2rem(15px);
      color:$wechatColor;
    }
  }

  &--finish {
    >.step__line {
      &::after {
        background-color: $wechatColor;
      }
    }
  }
}

</style>
