<template>
  <page-view bg-color="#f5f5f5">
    <template v-slot:nav>
      <nav-bar v-if="appVersionInfo.isShowNavBar"
               @click-left="handleBack"
               title="小程序设置"
      >
      </nav-bar>
    </template>
    <stepper :active="3" class="marginlr-25 stepper-item" />
    <div class="content mt-20 cell-containner">
      <van-cell-group>
        <van-cell title="小程序名称"
                  @click="$router.push({name: 'minProgramVerifyDetail', query:{nick_name: baseData.nick_name}})"
                  is-link
                  :value="baseData.nick_name || '请输入您的小程序名称'"
        />
        <van-cell title="小程序头像"
                  center
                  is-link
                  @click="$router.push({name: 'minProgramVerifyDetail', query:{miniAppLogo: baseData.head_image_info}})"
        >
          <van-image :src="baseData.head_image_info?baseData.head_image_info.head_image_url:'' "
                     class="img-content"
          />
        </van-cell>
      </van-cell-group>
    </div>
    <span class="tips">*点击可修改小程序名称和头像</span>
    <div class="text-center mt-30">
      <template v-if="nameData.audit_stat == 2">
        <i class="icon-shibai iconfont2 fail-icon font-24 process-icon"></i>
        <p class="text-color dark-gray font-18 mb-5 lh-1">审核失败</p>
        <p class="text-color gray font-15 fail_reason">{{ nameData.fail_reason }}</p>
      </template>
      <template v-if="nameData.audit_stat == 1">
        <i class="icon-shijian iconfont2 process-icon"></i>
        <p class="text-color dark-gray font-18 mb-5 lh-1">审核中</p>
      </template>
    </div>
  </page-view>
</template>
<script>
import appVersionMixins from '@/minxins/app-version-mixins'
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import { Cell, CellGroup, Image as VanImage } from 'vant'
import Stepper from '../attest-miniprogram/modules/stepper'
import AttestMiniprogramModel from '@/model/attest-miniprogram/index'
export default {
  name: '',
  mixins: [appVersionMixins],
  components: {
    PageView,
    Stepper,
    NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [VanImage.name]: VanImage
  },
  props: {},
  data () {
    return {
      nameData: {},
      baseData: {},
      showTip: false,
      loading: false, // 加载中
      loaded: false, // 加载完成
      totalMoney: null,
      empty: false,
      noMore: false
    }
  },

  watch: {},
  async created () {
    const json = await AttestMiniprogramModel.getSettingInfo()
    const { result, data } = json
    if (result) {
      if (data == null) {
        this.baseData = {
          nick_name: null,
          head_image_info: {}
        }
        return
      }
      this.baseData = data
    }
    const nameData = await AttestMiniprogramModel.checkNameState()
    this.nameData = nameData.data
  },
  methods: {
    handleBack () {
      // eslint-disable-next-line no-undef
      window.$bridge && $bridge.navigation.popViewController()
    }
  },
  beforeDestroy () {
    this.$bus.$emit('nameData', this.nameData)
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
