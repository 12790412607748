import { apiUrl } from '@/config/net'
import api from '@/tools/api-tools'

class positionData {
  // 原始数据
  originalData (data) {
    return data.map(item => {
      return {
        id: item.id,
        displayName: item.displayName,
        oriItem: item
      }
    })
  }

  // 获取数据
  dataSchema () {
    return api.bget(`https:${apiUrl}/mobile/security/user-positions`).then(res => {
      const data = res.data.data || {}
      return this.originalData(data)
    })
  }
}

export default positionData
