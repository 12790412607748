/**
 * 生成大图方法
 * @param {*} goodsImg
 * @param {*} qrPic
 * @param {*} shopInfo
 * @param {*} goodsInfo
 */
export function downloadPic (canvas, ctx, goodsImg, qrPic, shopInfo, goodsInfo) {
  const imgWidth = 750
  const imgHeight = 1022
  canvas.width = imgWidth
  canvas.height = imgHeight
  Promise.all([loadPic(goodsImg), loadPic(qrPic)]).then(res => {
    // 画背景
    fillRoundRect(ctx, 0, 0, imgWidth, imgHeight, 24, '#fff')
    const [goodsPicObj, qrPicObj] = res
    // 1.画大图
    // 1.1 处理圆角图片路径
    ctx.save()
    ctx.beginPath()
    // 矩形下边线
    ctx.lineTo(750, 750)
    ctx.lineTo(0, 750)
    const radius = 24
    // 矩形左边线
    ctx.lineTo(0, radius)
    // 左上角圆弧，弧度从PI到3/2PI
    ctx.arc(radius, radius, radius, Math.PI, Math.PI * 3 / 2)
    // 上边线
    ctx.lineTo(750 - radius, 0)
    // 右上角圆弧
    ctx.arc(750 - radius, radius, radius, Math.PI * 3 / 2, Math.PI * 2)
    // 右边线
    ctx.lineTo(750, 750)
    ctx.clip()

    // 1.2 画图
    const imgOrigin = checkImgSize(goodsPicObj)
    ctx.drawImage(
      res[0],
      imgOrigin.sx,
      imgOrigin.sy,
      imgOrigin.sWidth,
      imgOrigin.sHeight,
      0,
      0,
      imgWidth,
      imgWidth
    )
    // 2.1 画图片渐变
    ctx.restore()
    const goodsNameGradient = ctx.createLinearGradient(0, 375, 0, 750)
    goodsNameGradient.addColorStop(0, 'rgba(0,0,0,0)') // 定义黄色渐变色
    goodsNameGradient.addColorStop(1, 'rgba(0,0,0,0.3)') // 定义红色渐变色
    ctx.fillStyle = goodsNameGradient // 设置fillStyle为当前的渐变对象
    ctx.fillRect(0, 375, 750, 375) // 绘制渐变图形
    const MAXT_TEXTHEIGHT = 680 // 文字最大宽度
    const LINEHEIGHT = 56 // 行高
    // 2.2 画商品名称
    ctx.fillStyle = '#fff'
    const goodsNameArr = wrapText({
      width: MAXT_TEXTHEIGHT,
      size: 40,
      text: goodsInfo.name
    }, ctx)
    for (let i = 0; i < goodsNameArr.length; i++) {
      ctx.fillText(goodsNameArr[i], 36, 670 + (i + 1 * LINEHEIGHT), MAXT_TEXTHEIGHT)
    }
    // 3.画价格
    ctx.font = 'bold 50px sans-serif'
    ctx.fillStyle = '#FF4949'
    ctx.fillText(`￥${goodsInfo.price}`, 35, 779 + 53)
    // 4.店铺名称和手机号
    ctx.font = 'bold 30px sans-serif'
    ctx.fillStyle = '#666'
    const maxtShopNameLength = 8
    const shopName = shopInfo.shopName.length > maxtShopNameLength ? shopInfo.shopName.substr(0, maxtShopNameLength) : shopInfo.shopName
    const txt = shopInfo.phone ? `${shopName} / ${shopInfo.phone}` : `${shopName}`
    ctx.fillText(txt, 35, 884 + 29)
    // 5. 店铺地址

    const MAXT_ADDRESSWIDTH = 512 // 地址最大宽度
    const addressArr = wrapText({
      width: MAXT_ADDRESSWIDTH,
      size: 26,
      text: shopInfo.address && shopInfo.address.length > 46 ? shopInfo.address.substr(0, 46) + '...' : shopInfo.address
    }, ctx)
    for (let i = 0; i < addressArr.length; i++) {
      ctx.fillText(addressArr[i], 35, 925 + ((i + 1) * 26), MAXT_ADDRESSWIDTH)
    }
    // 6.画二维码
    ctx.drawImage(qrPicObj, 547, 779, 180, 180)
    // 7.画版权
    ctx.font = '16px sans-serif'
    ctx.fillStyle = '#ccc'
    ctx.fillText('生意专家提供技术支持', 285, 986 + 16)
  })
}

/**
 * 加载图片
 * @param {} path
 */
function loadPic (path) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.setAttribute('crossOrigin', 'anonymous')
    img.src = path
    img.onload = () => {
      resolve(img)
    }
    img.onerror = (e) => {
      reject(new Error(e))
    }
  })
}

function checkImgSize (imgInfo) {
  const {
    height,
    width
  } = imgInfo
  const maxHeight = 750
  const maxWidth = 750
  if (width > height) { // 正方的长方形
    return {
      sx: (width - height) / 2,
      sy: 0,
      sWidth: height > maxHeight ? maxHeight : height,
      sHeight: height > maxHeight ? maxHeight : height
    }
  } else if (width < height) { // 竖立的长方形
    return {
      sx: 0,
      sy: (height - width) / 2,
      sWidth: width > maxWidth ? maxWidth : width,
      sHeight: width > maxWidth ? maxWidth : width
    }
  } else {
    return {
      sx: 0,
      sy: 0,
      sWidth: width > maxWidth ? maxWidth : width,
      sHeight: height > maxHeight ? maxHeight : height
    }
  }
}

/**
 * 传入文字，根据最大宽度来确认文字是否要换行
 * @param {Object} obj
 * @param {*} ctx
 */
function wrapText (obj, ctx) {
  ctx.font = `${obj.size}px sans-serif`
  const arrText = obj.text.split('')
  let line = ''
  const arrTr = []
  for (let i = 0; i < arrText.length; i++) {
    var testLine = line + arrText[i]
    var metrics = ctx.measureText(testLine)
    var width = metrics.width
    if (width > obj.width && i > 0) {
      arrTr.push(line)
      line = arrText[i]
    } else {
      line = testLine
    }
    if (i === arrText.length - 1) {
      arrTr.push(line)
    }
  }
  return arrTr
}

function fillRoundRect (cxt, x, y, width, height, radius, fillColor) {
  // 圆的直径必然要小于矩形的宽高
  if (2 * radius > width || 2 * radius > height) { return false }
  cxt.save()
  cxt.translate(x, y)
  // 绘制圆角矩形的各个边
  drawRoundRectPath(cxt, width, height, radius)
  cxt.fillStyle = fillColor || '#000' // 若是给定了值就用给定的值否则给予默认值
  cxt.fill()
  cxt.restore()
}

function drawRoundRectPath (cxt, width, height, radius) {
  cxt.beginPath(0)
  // 从右下角顺时针绘制，弧度从0到1/2PI
  cxt.arc(width - radius, height - radius, radius, 0, Math.PI / 2)
  // 矩形下边线
  cxt.lineTo(radius, height)
  // 左下角圆弧，弧度从1/2PI到PI
  cxt.arc(radius, height - radius, radius, Math.PI / 2, Math.PI)
  // 矩形左边线
  cxt.lineTo(0, radius)
  // 左上角圆弧，弧度从PI到3/2PI
  cxt.arc(radius, radius, radius, Math.PI, Math.PI * 3 / 2)
  // 上边线
  cxt.lineTo(width - radius, 0)
  // 右上角圆弧
  cxt.arc(width - radius, radius, radius, Math.PI * 3 / 2, Math.PI * 2)
  // 右边线
  cxt.lineTo(width, height - radius)
  cxt.closePath()
}
