<template>
  <van-uploader class="uploader--dashed"
                :class="[className]"
                v-model="fileList"
                :max-count="maxCount"
                :max-size="maxSize"
                :disabled="disabled"
                @delete="handleDelete"
                :after-read="afterRead"
  >
    <slot></slot>
  </van-uploader>
</template>
<script>
import { Uploader } from 'vant'
import upload from './ajax'
import Upyun from './upyun'
import api from '@/tools/api-tools'
import * as Urls from '@/config/net'
import compressImg from './compressImg'
import fixImageOrientation from './fixImageOrientation'
export default {
  name: 'Uploader',
  mixins: [],
  components: { [Uploader.name]: Uploader },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: [Array, Object, String],
    // 成功回调事件
    onSuccess: {
      type: Function,
      default: () => {}
    },
    maxCount: { // 默认为1
      type: Number,
      default: 1
    },
    // eslint-disable-next-line vue/require-default-prop
    maxSize: Number,
    size: {
      type: String, // 支持40  和  50
      default: '50'
    },
    compressImg: {
      type: Boolean,
      default: true
    },
    theme: {
      type: String, // default 和 orange
      default: 'default'
    },
    disabled: Boolean,
    returnDataFormat: { // 返回的数据格式，这个参数主要是为了兼容之前的数据，标准的是单个的时候返回对象，多个返回数组
      type: String,
      default: '' // array 、default
    },
    needHttp: { // 是否带Http头
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      uploadConfig: null,
      fileList: this._formatValue()
    }
  },
  computed: {
    className () {
      const targetClass = []
      targetClass.push(`uploader--${this.size}`)
      targetClass.push(`uploader--${this.theme}`)
      return targetClass.join(' ')
    }
  },
  watch: {
    value: {
      handler (newValue, oldValue) {
        this.fileList = this._formatValue()
      },
      deep: true
    }
  },
  created () { },
  async mounted () {
    Upyun.setApi(api, Urls.apiUrl)
    this.uploadConfig = await Upyun.getUploadConfig()
  },
  destroyed () { },
  methods: {
    /**
     * fileDetail: { name: '', index: 0 }
     */
    async afterRead (file, fileDetail) {
      file.status = 'uploading'
      file.message = '上传中'
      const fileName = file.file.name
      // 修正图片问题方向
      file.file = await fixImageOrientation(file.file)
      if (this.compressImg) {
        file.file = await compressImg(file.file)
      }
      const options = {
        file: this._fixFileName(file.file, fileName),
        data: this.uploadConfig,
        filename: 'file',
        action: Upyun.actionUrl,
        onProgress: e => {
          const { percent } = e
          file.status = 'uploading'
          file.message = percent.toFixed(0) + '%'
        },
        onSuccess: res => {
          file.status = 'done'
          file.url = Upyun.fullImgUrl(res.url)
          // 处理移动端上传时文件名会丢失的问题
          const { url } = res
          const params = { url: Upyun.fullImgUrl(url), index: fileDetail.index }
          if (this.onSuccess) { // 执行钩子函数
            this.onSuccess(params)
          }
          this._emitData()
        },
        onError: e => {
          file.status = 'failed'
          file.message = '上传失败'
        }
      }
      // 又拍云上传
      upload(options)
    },
    /**
     * 删除图片事件
     */
    handleDelete (file, fileDetail) {
      this._emitData()
    },
    /**
     * emitData,
     * 上传图片只有1个的时候返回为一个字符，多个的时候返回为数组
     */
    _emitData () {
      if (this.maxCount === 1 && this.returnDataFormat === 'default') { // 单个返回对象
        const [firstPath = null] = this.fileList.map(e => {
          return { url: this._formatImgUrl(e.url) }
        })
        this.$emit('input', firstPath)
      } else if (this.maxCount === 1 && this.returnDataFormat === 'string') { // 返回单个的string
        const [firstPath = null] = this.fileList.map(e => {
          return this._formatImgUrl(e.url)
        })
        this.$emit('input', firstPath)
      } else {
        this.$emit('input', this.fileList.map(e => {
          return { url: this._formatImgUrl(e.url) }
        }))
      }
    },
    _formatValue () {
      if (typeof this.value === 'string') {
        if (this.value.length) {
          return [{ url: this.value }]
        } else {
          return []
        }
      } else if (!this.value) { // 返回值为null 情况的处理
        return []
      } else if (!Array.isArray(this.value)) { // 对象情况
        return [this.value]
      } else {
        return this.value
      }
    },
    /**
     * 格式化导出的URL路径
     */
    _formatImgUrl (fullUrl) {
      if (!this.needHttp) {
        fullUrl = fullUrl.replace(/(http|https):/g, '')
      }
      return fullUrl
    },
    // 修复移动端下上传的是Blob，文件名丢失的情况
    _fixFileName (theBlob, fileName) {
      if (!(theBlob instanceof File)) { // blob
        return new File([theBlob], fileName)
      }
      return theBlob
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../assets/less/theme/over-write.less';
.uploader--dashed {
  /deep/ .van-uploader__upload {
    background: #fff;
    &::after {
      position: absolute;
      box-sizing: border-box;
      content: ' ';
      pointer-events: none;
      top: -50%;
      right: -50%;
      bottom: -50%;
      left: -50%;
      border: 0 solid #ddd;
      transform: scale(0.5);
      border-style: dashed;
      border-radius: 16px;
      border-width: 1px;
    }
  }
}

.uploader--50 {
   /deep/ .van-uploader__upload {
      width: 50px;
      height: 50px;
   }
   /deep/ .van-uploader__loading {
      height: 18px;
      width: 18px;
    }
   /deep/ .van-uploader__mask-message {
     font-size: 8px;
     padding: 0 2px;
   }
   /deep/ .van-uploader__preview-image {
     width: 50px;
     height: 50px;
   }
   /deep/ .van-uploader__file {
     width: 50px;
     height: 50px;
   }
}

.uploader--40 {
  /deep/ .van-uploader__upload {
      width: 40px;
      height: 40px;
   }
  /deep/ .van-uploader__preview-image {
     width: 40px;
     height: 40px;
   }
}

.uploader--orange {
  /deep/ .van-uploader__upload {
     &::after {
       border-color: @orange;
     }
  }
  /deep/ .van-uploader__upload-icon {
    color: @orange;
  }
}
</style>
