import api from '@/tools/api-tools'
import { apiUrlV1, apiUrl, mscUrl, truckApiUrl} from '@/config/net'

export default class miniProgramModel {
  /**
   * 获取商品列表
   * @param {Object} params 分页、关键词 http://msc-api.laoban100.com/goods-management/goods/list?start=40&limit=20&categoryId=0&subCategoryId=&status=&keyword=
   */
  static getGoodsList (params = { start: 0 }) {
    return api.get(`${mscUrl}/goods-management/goods/list?start=${params.start}&limit=${params.pageSize}&categoryId=0&subCategoryId=&status=&keyword=`, params).then(res => res.data)
  }

  /**
   * 获取商品详情 http://api.laoban100.com/api/goods/40555118
   * http://webapi.laoban100.com/40605384
   */
  static getGoodsDetail (goodsId) {
    return api.get(`${apiUrl}/albert/goods/goodsinfo?gid=${goodsId}`).then(res => res.data)
  }

  /**
   * 获取小程序商品详情  http://webapi.laoban100.com/v0/40581990 http://msc-api.laoban100.com/goods-management/goods/detail?goodsId=42450776
   */
  static getMiniAppGoodsDetail (goodsId) {
    return api.get(`${mscUrl}/goods-management/goods/detail?goodsId=${goodsId}`).then(res => res.data)
  }

  /**
   * 保存  http://webapi.laoban100.com/v0/mobile/miniapp-goods http://msc-api.laoban100.com/goods-management/goods/
   */
  static saveChange (params) {
    return api.put(`${mscUrl}/goods-management/goods`, params).then(res => res.data)
  }

  /**
   * 是否到达分享上限  /goods-management/goods/goods-informality-share-islimit
   */
  static getShareLimit () {
    return api.get(`${mscUrl}/goods-management/goods/goods-informality-share-islimit`).then(res => res.data)
  }

  /**
   * 获取分享小程序码${truckApiUrl}/v0/mobilecase/mini-app/mobile-product-qrcode?productId=40536523
   */
  static getShareQcode (goodsId) {
    return api.get(`${truckApiUrl}/v0/mobilecase/mini-app/mobile-product-qrcode?productId=${goodsId}`).then(res => res.data)
  }

  /**
   * 小程序商品上架http://msc-api.laoban100.com/goods-management/goods/onsale
   */
  static updateMiniprogram (goodsId) {
    return api.put(`${mscUrl}/goods-management/goods/onsale`, [goodsId]).then(res => res.data)
  }

  /**
   * 获取商品扩展信息
   * @param {Object} params 商品id
   */
  static getGoodsExtendInfo (params) {
    return api.post(`${apiUrlV1}/mobile/goods/goodsskulist`, params).then(res => res.data)
  }

  /**
   * 上传又拍云
   */
  static getSign (params) {
    return api.get(`${apiUrl}/cebcollection/uploadconfig`, params).then(res => res.data)
  }

  /**
   * 上传图片
   */
  static uploadImg (params) {
    return api.get(`https://v1.api.upyun.com/img-i200/`, params).then(res => res.data)
  }

  /**
   * 新增商品
   */
  static addGoods (params) {
    return api.post(`${apiUrl}/mobile/goods/add`, params).then(res => res.data)
  }

  /**
   * 新增商品时保存商品信息
   */
  static saveGoodsDetail (params) {
    return api.post(`${apiUrlV1}/mobile/goods/goodsEdit`, params).then(res => res.data)
  }

  /**
   * 获取店铺信息 http://webapi.ilaoban100.com/v0/mobilecase/miniapp-account-settinginfo
   */
  static getBaseInfo () {
    return api.get(`${apiUrl}/mobilecase/miniapp-account-settinginfo`).then(res => res.data)
  }
}
