<template>
  <div class="price" :style="styles" v-html="priceShow">
    <span></span>
  </div>
</template>
<script>
export default {
  name: 'Price',
  props: {
    price: {
      type: [Number, String],
      default: 0
    },
    color: {
      type: String,
      default: ''
    },
    needSymbol: {
      type: Boolean,
      default: true
    },
    decimalDigits: {
      type: [Number, String],
      default: 2
    },
    thousands: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    priceShow () {
      const self = this
      const symbol = self.needSymbol
        ? '<span class="price-symbol">￥</span>'
        : ''
      return symbol + self.formatToHump(self.price)
    },
    styles () {
      if (this.color) {
        return { color: this.color }
      }
      return {}
    }
  },
  data () {
    return {}
  },
  methods: {
    // 判断是否为小数点
    checkPoint (num) {
      return String(num).indexOf('.') > 0
    },
    // 将数字转换成驼峰形式
    formatToHump (num) {
      const self = this
      num = String(num).replace('￥', '')
      if (self.checkPoint(num)) {
        const numArray = String(num).split('.')
        return (
          '<span class="price-big">' +
          self.formatThousands(numArray[0]) +
          '</span><span class="price-point">.</span><span class="price-small">' +
          self.formatDecimal(numArray[1]) +
          '</span>'
        )
      } else {
        return (
          '<span class="price-big">' +
          self.formatThousands(num) +
          '</span><span class="price-point">.</span><span class="price-small">' +
          self.formatDecimal(0) +
          '</span>'
        )
      }
    },
    // 根据小数位数格式化小数部分
    formatDecimal (decimalNum) {
      const self = this
      const decimalDigits = self.decimalDigits
      const result = '0.' + String(decimalNum)
      const resultFixed = (result / 1).toFixed(decimalDigits)
      return String(resultFixed).substring(2, resultFixed.length)
    },
    // 千分位显示
    formatThousands (num) {
      const self = this
      // let
      if (self.thousands) {
        return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      } else {
        return num
      }
    }
  }
}
</script>
<style lang="less">
@import "./index.less";
</style>
