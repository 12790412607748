<template>
  <page-view bg-color="#f5f5f5">
    <template v-slot:nav>
      <nav-bar v-if="appVersionInfo.isShowNavBar" title="小程序"></nav-bar>
    </template>
    <stepper :active="3" class="marginlr-25 stepper-item" />
    <div class="mt-20 cell-containner">
      <van-field
        label="小程序名称"
        clearable
        v-if="$route.query.hasOwnProperty('nick_name')"
        input-align="right"
        placeholder="请输入您的小程序名称"
        v-model="nickName"
        :readonly="readonly"
      />
    </div>
    <!-- 小程序头像 -->
    <span class="tips text-color orange" v-html="warning"></span>
    <div class="upload-content mt-30" v-if="$route.query.hasOwnProperty('miniAppLogo')">
      <!-- <c-upload :url.sync="avatar"></c-upload> -->
      <uploader v-model="avatar" returnDataFormat="string" />
      <div class="pt-15 font-15 font-color-6">小程序头像</div>
    </div>
    <div
      class="upload-content mt-30 ds-flex justify-center"
      v-if="$route.query.hasOwnProperty('nick_name')"
    >
      <div class="upload-item" v-show="isShowLicense || isShowCertificate">
        <uploader v-model="LicenseUrl" returnDataFormat="string" />
        <!-- <c-upload :url.sync="LicenseUrl"></c-upload> -->
        <div class="pt-15 font-15 font-color-6">上传营业执照</div>
      </div>
      <div class="upload-item ml-10" v-show="isShowCertificate">
        <uploader v-model="certificateUrl" returnDataFormat="string" />
        <!-- <c-upload :url.sync="certificateUrl"></c-upload> -->
        <div class="pt-15 font-15 font-color-6">上传资质证明</div>
      </div>
    </div>
    <span class="tips" v-html="tips"></span>
    <template v-slot:footer>
      <van-button class="footer-btn-group" @click="submit" type="primary" block>保存</van-button>
    </template>
  </page-view>
</template>
<script>
// import throttle from 'lodash.throttle'
import appVersionMixins from '@/minxins/app-version-mixins'
import PageView from '@/layouts/PageView'
import Uploader from '@/components/uploader'
import { Field, Button, Toast } from 'vant'
import NavBar from '@/components/nav-bar'
import AttestMiniprogramModel from '../../model/attest-miniprogram/index'
import Stepper from '../attest-miniprogram/modules/stepper'
export default {
  name: '',
  mixins: [appVersionMixins],
  components: {
    NavBar,
    Stepper,
    PageView,
    Uploader,
    [Field.name]: Field,
    [Button.name]: Button,
    [Toast.name]: Toast
  },
  props: {},
  data () {
    return {
      warning: '',
      isShowLicense: false,
      certifiMediaId: '',
      licenseMediaId: '',
      certificateUrl: '',
      isShowCertificate: false,
      nameData: {},
      // formData: {...this.$route.query},
      LicenseUrl: '',
      nickName: this.$route.query.nick_name,
      avatar: this.$route.query.miniAppLogo ? this.$route.query.miniAppLogo.head_image_url : '',
      chekNameResult: {},
      columns: [
        {
          value: 'A',
          children: [
            { value: 'A-a' },
            { value: 'A-b' },
            { value: 'A-c' }
          ]
        },
        {
          value: 'B',
          children: [
            { value: 'B-a' },
            { value: 'B-b' }
          ]
        }
      ],
      active: 3,
      swichStatus: false,
      MiniAppLogo: null,
      MiniAppLogoMediaId: null
    }
  },
  async created () {
    this.$bus.$on('nameData', (res) => {
      this.nameData = res
    })
    // let nameData = await AttestMiniprogramModel.checkNameState()
    // this.nameData = nameData.data
    // this.nameData = nameData.data
    // AttestMiniprogramModel.getAllcatagory().then(res => {
    //   console.log('res1', res)
    // })
    // this.formData = {...this.formData, ...this.$route.query}
  },
  mounted () {
  },
  methods: {
    confirmBaseInfo () {
      if (!this.testNotNull(this.nickName)) {
        Toast('请输入用户名')
        return false
      }
      if (this.isShowLicense && !this.testNotNull(this.LicenseUrl)) {
        Toast('请上传营业执照')
        return false
      }
      if (this.isShowCertificate && !this.testNotNull(this.certificateUrl)) {
        Toast('请上传资质证明')
        return false
      }
      return true
    },
    // 验证不能为空
    testNotNull (value) {
      return value && value.length !== 0
    },
    async submitName (params) {
      const data = await AttestMiniprogramModel.submitName(params)
      if (!data.isSuccessful) {
        Toast(data.message)
        return
      }
      this.$router.go(-1)
    },
    async submit () {
      // eslint-disable-next-line no-prototype-builtins
      if (this.$route.query.hasOwnProperty('nick_name')) {
        if (!this.confirmBaseInfo()) return
        const data = await AttestMiniprogramModel.checkInputName(this.nickName)
        if (data.isSuccessful) {
          if (data.data.hit_condition) {
            this.warning = data.data.wording
            if (data.data.wording.indexOf('通用词') !== -1) {
              this.isShowLicense = true
              if (!this.testNotNull(this.LicenseUrl)) return
              const params = {
                MiniAppName: this.nickName,
                LicenseUrl: this.LicenseUrl,
                LicenseMediaId: this.licenseMediaId
              }
              this.submitName(params)
            } else {
              this.isShowCertificate = true
              if (!this.testNotNull(this.certificateUrl)) return
              const params = {
                MiniAppName: this.nickName,
                LicenseUrl: this.LicenseUrl,
                LicenseMediaId: this.licenseMediaId,
                naming_other_stuff_1: this.certifiMediaId
              }
              this.submitName(params)
            }
          } else {
            this.isShowLicense = true
            if (!this.testNotNull(this.LicenseUrl)) return
            const params = { MiniAppName: this.nickName, LicenseUrl: this.LicenseUrl, LicenseMediaId: this.licenseMediaId }
            this.submitName(params)
          }
        } else if (data.message.length) {
          this.warning = data.message
        } else {
          Toast('您申请的名称不符合微信规范，请更换其他名称重新提交')
        }
        // eslint-disable-next-line no-prototype-builtins
      } else if (this.$route.query.hasOwnProperty('miniAppLogo')) {
        const params = {
          MiniAppLogo: this.MiniAppLogo,
          MiniAppLogoMediaId: this.MiniAppLogoMediaId,
          X1: 0,
          Y1: 0,
          X2: 1,
          Y2: 1
        }
        AttestMiniprogramModel.setLogo(params).then(res => {
          if (res.result) {
            this.$router.go(-1)
          } else {
            Toast(res.message)
          }
        })
      }
    },
    show () {
      this.$refs.picker.show()
    },
    switchChange (val) {
      this.formData.miniAppSearchState = val
    }
  },
  watch: {
    certificateUrl (v) {
      if (!v) return
      AttestMiniprogramModel.imgTomediaId({ certificateUrl: v }).then(res => {
        this.certifiMediaId = res.data
      })
    },
    LicenseUrl (v) {
      if (!v) return
      AttestMiniprogramModel.imgTomediaId({ MediaUrl: v }).then(res => {
        this.licenseMediaId = res.data
      })
    },
    avatar (v, o) {
      if (!v) return
      AttestMiniprogramModel.imgTomediaId({ MediaUrl: v }).then(res => {
        this.MiniAppLogo = v
        this.MiniAppLogoMediaId = res.data
      })
    }
  },
  computed: {
    readonly () {
      if (this.nameData.audit_stat === 1) {
        return true
      }
      return false
    },
    showClearIcon () {
      if (this.nameData.audit_stat === 1) {
        return false
      }
      return true
    },
    tips () {
      const query = this.$route.query
      // eslint-disable-next-line no-prototype-builtins
      if (query.hasOwnProperty('intro')) {
        return '1-2句介绍小程序主要功能，建议60个汉字以内。'
        // eslint-disable-next-line no-prototype-builtins
      } else if (query.hasOwnProperty('secret')) {
        return '打开后，您的小程序可以被搜索。'
        // eslint-disable-next-line no-prototype-builtins
      } else if (query.hasOwnProperty('miniAppLogo')) {
        return `注意：</br>小程序头像每个月固定的修改次数上限</br>
          图片格式只支持：BMP、JPEG、JPG、GIF、PNG，大小不超过 2M</br>
          实际头像始终为正方形`
      }
      return ''
    }
  },
  destroyed () {
    this.$bus.$off('nameData')
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
// @import '../attest-miniprogram/index.scss'
</style>
<style lang="scss">
.cell-containner {
  .weui-switch-cp__input_overwrite.checked ~ .weui-switch-cp__box {
    background: #17bf62 !important;
    border-color: transparent !important;
  }
}

// .secret {
//   .weui-cell_access .weui-cell__ft {
//     padding-right: 0;
//     &:after {
//       display: none !important
//     }
//   }
// }
</style>
