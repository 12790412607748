// 推荐
import Vue from 'vue'
import Router from 'vue-router'
import AccquireIntroRouter from './acquire-intro'
import MiniProgramRouter from './mini-program'
import PermissionRouter from './permission'
import SaleRouter from './sales'
import WechatCardRouter from './wechat-card'

Vue.use(Router)
const router = new Router({
  routes: [
    {
      path: '/',
      component: () => import('@/views/hello.vue'),
      children: []
    }, {
      path: '/multiple-sale', // 核销页面
      component: () => import('@/views/multiple-sale')
    }, {
      path: '/passenger-flow', // 客流分析
      component: () => import('@/views/passenger-flow'),
      children: []
    }, {
      path: '/login', // 登录信息页
      component: () => import('@/views/login'),
      children: []
    },
    {
      path: '/test', // 登录信息页
      component: () => import('@/views/test'),
      children: []
    },
    ...SaleRouter,
    ...AccquireIntroRouter,
    ...MiniProgramRouter,
    ...PermissionRouter,
    ...WechatCardRouter
  ]
})

router.beforeEach((to, from, next) => {
  if (!('$cRouter' in Vue.prototype)) {
    Vue.prototype.$cRouter = {
      path: to.path
    }
  }
  next()
})

export default router
