import { apiUrl } from '@/config/net'
import api from '@/tools/api-tools'

class FormData {
  constructor () {
    this.formData = {
      userName: null, // 员工姓名
      permissionIds: [] // 登录账号
    }
  }

  // 更新权限
  submit () {
    const permissionData = {
      displayName: this.formData.userName,
      permissionIds: this.formData.permissionIds
    }
    const url = `https:${apiUrl}/mobile/security/user-position`
    return api.bpost(url, permissionData).then(res => {
      return res
    })
  }

  // 更新职位权限
  static updatePermission (positionId, displayName, permissionIds) {
    const permissionData = {
      displayName,
      permissionIds
    }
    const url = `https:${apiUrl}/mobile/security/user-position/${positionId}`
    return api.bpost(url, permissionData).then(res => {
      const data = res.data || {}
      return data.code === 0
    })
  }
}

export default FormData
