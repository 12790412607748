import { getCookie } from '@/tools/cookie'
import device from '@/tools/device'
// 获取app版本号信息
const appVersionMixins = {
  data () {
    return {
      appVersionInfo: {
        version: null,
        isShowNavBar: true // 是否显示导航
      }
    }
  },
  mounted () {
    this.appVersionMixins_getVersionInfo()
  },
  methods: {
    appVersionMixins_getVersionInfo () {
      if (device.ios) {
        const LASTVERSION = '3.5.2' // 写此代码时目前线上最新的版本
        const currentAppVersion = getCookie('version')
        if (currentAppVersion) { // 新版本的有cookies
          this.appVersionInfo.version = currentAppVersion
          this.appVersionInfo.isShowNavBar = !!versionCompare(currentAppVersion, LASTVERSION)
        } else {
          this.appVersionInfo.isShowNavBar = false
        }
      } else if (device.android) { // 安卓
        const OLDLASTSTR = 'i200SYZJ'
        const ua = window.navigator.userAgent
        const uaArr = ua.split(';')
        const last = uaArr[Math.max(0, uaArr.length - 1)].trim()
        if (last && last === OLDLASTSTR) {
          this.appVersionInfo.version = last
          this.appVersionInfo.isShowNavBar = false
        }
      }
    }
  }
}

export default appVersionMixins

/*
 * change version string to number
 * 转换数字型版本号字符串为数值
 */
function versionToNumber (version, length, exponent) {
  var arr
  if (arguments.length < 3) {
    return 0
  }
  arr = version.split('.')
  version = 0
  arr.forEach(function (value, index, array) {
    version += value * Math.pow(10, length * exponent - 1)
    length--
  })
  return version
}

/*
* compare version
* 比较版本号
* 仅适用数字型的版本号
* 0: 相等
* 1: 大于
* -1: 小于
*/
function versionCompare (version, targetVersion, exponent) {
  var getVersionNumber, length
  exponent = exponent || 2
  if (!version || !targetVersion) {
    throw new Error('Need two versions to compare!')
  }
  if (version === targetVersion) {
    return 0
  }
  length = Math.max(version.split('.').length, targetVersion.split('.').length)
  getVersionNumber = (function (length, exponent) {
    return function (version) {
      return versionToNumber(version, length, exponent)
    }
  })(length, exponent)
  version = getVersionNumber(version)
  targetVersion = getVersionNumber(targetVersion)
  return version > targetVersion ? 1 : (version < targetVersion ? -1 : 0)
}
