// namespace = userInfo
import { apiUrl } from '@/config/net'
import api from '@/tools/api-tools'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    accId: null,
    storeName: null,
    cardInfo: null,
  },
  getters: {
    getAccId (state) {
      return state.accId
    },
    getStoreName (state) {
      return state.storeName
    }
  },
  actions: {
    getBaseInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        api.get(`${apiUrl}/account/baseinfo`).then(res => {
          const data = res.data.data || {}
          const targetAccId = data.accountId.toString().substring(0, data.accountId.toString().length - 1)
          commit('setAccId', targetAccId)
          commit('setStoreName', data.accountName)
        })
        resolve()
      })
    },
    pvCount ({ state }, pid) {
      if (process.env.NODE_ENV !== 'production') return
      const accId = state.accId
      setTimeout(() => {
        axios.get(`//app.laoban100.com/Function/LogAction.ashx?type=weblog&pid=${pid}&accid=${accId}`)
      })
    }
  },
  mutations: {
    setCardInfo (state, data) {
      state.cardInfo = data
    },
    setAccId (state, data) {
      state.accId = data
    },
    setStoreName (state, data) {
      state.storeName = data
    }
  }
}
