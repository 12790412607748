const apiTimeout = 10000
let javaApi, apiUrl, truckApiUrl, mallMaterialBuyUrl, remoteLogUrl, imgUrl, wechatUrl, mscUrl, apiUrlV1, mainWeb

switch (process.env.NODE_ENV) {
  case 'production':
    javaApi = '//gateapi.laoban100.com'
    apiUrlV1 = '//webapi.laoban100.com/v1'
    apiUrl = '//webapi.laoban100.com/v0'
    truckApiUrl = '//webapi.laoban100.com'
    mainWeb = 'http://app-mall.laoban100.com/ApiProxy' // nginx to http://app.laoban100.com
    mallMaterialBuyUrl = '//app-mall.laoban100.com/shop_v5.html?redirectUrl=/hardwareMarket/detail/84'
    remoteLogUrl = '//monitor.fe.yuanbei.biz'
    imgUrl = '//img.laoban100.com'
    wechatUrl = '//wxcard-api.laoban100.com/v0'
    mscUrl = '//msc-api.laoban100.com'
    break
  case 'testing':
    javaApi = '//gateapi.laoban100.com'
    apiUrlV1 = '//webapi.laoban100.com/v1'
    apiUrl = '//webapi.laoban100.com/v0'
    truckApiUrl = '//webapi.laoban100.com'
    mainWeb = 'http://app-mall.laoban100.com/ApiProxy' // nginx to http://app.laoban100.com
    mallMaterialBuyUrl = '//re-staging-webapi.yuanbei.biz/shop_v5.html?redirectUrl=/hardwareMarket/detail/84'
    remoteLogUrl = '//monitor.fe.yuanbei.biz'
    imgUrl = '//img.laoban100.com'
    wechatUrl = '//wxcard-api.laoban100.com/v0'
    mscUrl = '//msc-api.laoban100.com'
    break
  case 'development':
    javaApi = '//gateapi.laoban100.com'
    apiUrlV1 = '//webapi.laoban100.com/v1'
    apiUrl = '//webapi.laoban100.com/v0'
    truckApiUrl = '//webapi.laoban100.com'
    mainWeb = 'http://app-mall.laoban100.com/ApiProxy' // nginx to http://app.laoban100.com
    mallMaterialBuyUrl = `//192.168.20.155:8080/#/shop_v5.html?redirectUrl=/hardwareMarket/detail/84`
    remoteLogUrl = ''
    imgUrl = '//img.laoban100.com'
    wechatUrl = '//wxcard-api.laoban100.com/v0'
    mscUrl = '//msc-api.laoban100.com'
    break
}

export { javaApi, apiUrlV1, apiTimeout, apiUrl, truckApiUrl, mallMaterialBuyUrl, remoteLogUrl, imgUrl, wechatUrl, mscUrl, mainWeb }
// eslint-disable-next-line no-multiple-empty-lines

