<template>
  <div class="sender-box">
    <div class="show-all"
         v-if="hideAllModel"
    >
      <span class="ji-icon">寄</span>
      <div class="sender-name sender-layout">
        <span>寄件人</span><span>
          <input type="text"
                 v-model="sendModel.name"
                 :placeholder="namePlaceholder"
                 class="input-common"
          >
          <!-- <span v-else
                  class="input-common">{{sendModel.name}}</span> -->

        </span>
      </div>
      <div class="sender-info sender-layout van-hairline--top">
        <span>电话</span>
        <!-- <div :placeholder="telephonePlaceholder"
             class="input-common telephone-div"
             @touchstart.stop="showNumberKeyBoard">
          {{sendModel.telephone}}
        </div> -->
        <input type="number"
               v-model="sendModel.telephone"
               placeholder="请输入电话号码"
               class="input-common telephone-div"
        >
      </div>

      <div class="sender-adress sender-layout van-hairline--top"
           @click="showAddress"
      >
        <span>省-市-区</span> <span>{{ sendModel.province }}</span>
      </div>
      <div class="sender-apt sender-layout van-hairline--top">
        <span>街道、门牌号</span>
        <span><input type="text"
                     v-model="sendModel.address"
                     :placeholder="addressPlaceholder"
                     class="input-common"
        >
        </span>
      </div>
      <div class="sure-btn van-hairline--top"
           @click="hideAll"
      >
        完成
      </div>
    </div>
    <div class="hide-all"
         v-else
    >
      <div class="hide-icon__box">
        <span class="hide-icon">寄</span>
      </div>
      <div class="send-info">
        <div>
          <span>{{ sendModel.name }}</span>
          <span>{{ sendModel.telephone }}</span>
        </div>
        <div>
          {{ sendModel.province }} {{ sendModel.address }}
        </div>
      </div>
      <div class="edit-box van-hairline--left"
           @click="closeBorderBox"
      >
        编辑
      </div>
    </div>
    <!-- <van-collapse v-model="activeNames">
      <van-collapse-item title="寄件信息"
                         name="1"
                         title-class="van-newtitle"
                         value-class="van-newvalue">

      </van-collapse-item>
    </van-collapse> -->
  </div>
</template>
<script>
import provinceList from './area.js'
export default {
  name: 'Sender',
  mixins: [],
  components: {},
  props: {
    sendModel: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      activeNames: ['1'],
      formData: {
        telephone: null,
        address: '',
        name: ''
      },
      areaList: provinceList,
      show: false,
      hideAllModel: true,
      time: null
    }
  },
  computed: {
    telephonePlaceholder () {
      return '请输入手机号'
    },
    addressPlaceholder () {
      return '请输入具体地址'
    },
    namePlaceholder () {
      return '请输入寄件人'
    }
  },
  watch: {
    sendModel: {
      handler: function (value) {
        this.$emit('senderInfo', value)
      },
      deep: true
    }
  },
  created () { },
  mounted () {
    this.time = setTimeout(() => {
      if (this.sendModel.name) {
        this.hideAllModel = false
        this.$forceUpdate()
      }
    }, 600)
  },
  destroyed () { },
  methods: {
    closeBorderBox () {
      this.hideAllModel = true
      this.$emit('hideboxSend', true)
    },
    hideAll () {
      if (!this.sendModel.name) {
        this.$toast('请输入寄件人姓名')
        return
      }
      if (!this.sendModel.telephone) {
        this.$toast('请输入寄件人手机号')
        return
      }
      if (!this.sendModel.province) {
        this.$toast('请输入省市区')
        return
      }
      if (!this.sendModel.address) {
        this.$toast('请输入地址')
        return
      }
      this.hideAllModel = false
      this.$emit('hideboxSend', false)
      clearTimeout(this.time)
    },
    showAddress () {
      this.$emit('showAddress', true)
    },
    showNumberKeyBoard () {
      this.$emit('showKeyBoard', 'send')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../../src/assets/scss/base/_mixin';
.sender-box {
  width: px2rem(690px);
  margin: px2rem(30px) auto 0;
  background-color: white;
  // height: 100vh;
  position: relative;
  .sender-layout {
    height: px2rem(94px);
    padding: 0 px2rem(30px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: px2rem(28px);
    }
    span:first-child {
      color: #333333;
    }
    span:last-child {
      color: #666666;
    }
  }
}

.hide-all {
  width: px2rem(690px);
  margin: px2rem(30px) auto 0;
  margin-bottom: 0px;
  // height: px2rem(152px);
  display: flex;
  position: relative;
  .hide-icon__box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: px2rem(80px);
    position: relative;
    .hide-icon {
      width: px2rem(40px);
      height: px2rem(40px);
      line-height: px2rem(46px);
      background: rgba(255, 106, 60, 1);
      border-radius: px2rem(20px);
      position: absolute;
      z-index: 1000;
      text-align: center;
      color: white;
      font-size: px2rem(28px);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .send-info {
    width: px2rem(411px);
    div:nth-child(1) {
      padding-top: px2rem(20px);

      span {
        color: #666666;
        font-size: px2rem(30px);
        font-weight: bold;
      }
    }
    div:nth-child(2) {
      color: #666666;
      font-size: px2rem(26px);
      padding-bottom: px2rem(20px);
    }
  }
  .edit-box {
    width: px2rem(116px);
    height: px2rem(90px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3894ff;
    font-size: px2rem(26px);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.sure-btn {
  height: px2rem(95px);
  text-align: center;
  line-height: px2rem(95px);
  color: #17bf62;
  font-size: px2rem(30px);
}
.van-newtitle {
  span {
    font-size: px2rem(30px) !important;
    color: #333333 !important;
  }
}

.van-newvalue {
  span {
    font-size: px2rem(30px) !important;
    color: #666666 !important;
  }
}

.ji-icon {
  width: px2rem(40px);
  height: px2rem(40px);
  line-height: px2rem(40px);
  background: rgba(255, 106, 60, 1);
  border-radius: px2rem(20px);
  position: absolute;
  z-index: 1000;
  text-align: center;
  color: white;
  font-size: px2rem(28px);
  top: px2rem(-10px);
  left: px2rem(-10px);
}

.input-common {
  float: right;
  border: none;
  text-align: right;
}
.PoperContentView {
  // height: 100vh;
  background-color: white;
}
.test {
  width: px2rem(100px);
  height: px2rem(100px);
}
.telephone-div {
  line-height: px2rem(94px);
  color: #666666;
  font-size: px2rem(30px);
  height: px2rem(94px);
  width: 50%;
}
</style>
