// 员工权限模块
import PermissionConfig from '@/views/permission/config'
export default [
  {
    path: '/permission/config',
    component: PermissionConfig,
    children: []
  }, {
    path: '/permission/add-staff', // 添加员工
    component: () => import('@/views/permission/add-staff'),
    children: [{
      path: 'select-position', // 选择职位
      component: () => import('@/views/permission/select-position'),
      children: [{
        path: 'add-position', // 添加职位
        component: () => import('@/views/permission/add-position'),
        children: [{
          path: 'config', // 权限配置
          component: PermissionConfig,
          children: []
        }]
      }]
    }, {
      path: 'config', // 权限配置
      component: PermissionConfig,
      children: []
    }]
  }, {
    path: '/permission/add-position', // 添加职位
    component: () => import('@/views/permission/add-position'),
    children: []
  }
]
