// 销售模块路由
export default [
  {
    path: '/account-statement',
    component: () => import('@/views/account-statement')
  },
  {
    path: '/analyze-chart', // 销售分析
    component: () => import('@/views/analyze-chart'),
    children: []
  }, { // 销售设置
    path: '/setting/sales',
    component: () => import('@/views/setting/sales'),
    children: [
      {
        path: 'epayment',
        component: () => import('@/views/setting/epayment-gather'),
        children: [
          {
            path: 'pattern',
            component: () => import('@/views/setting/epayment-gather/gatheringPattern')
          }
        ]
      }
    ]
  }
]
