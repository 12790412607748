<template>
  <page-view style="background-color:#F2F2F2;">
    <template v-slot:nav>
      <nav-bar title="商品信息" leftArrow></nav-bar>
    </template>
    <div style="background-color:#F2F2F2;height:100vh">
      <div class="sharegoods-box">
        <div class="goods-content">
          <div class="goods-content__upload">
            <ul>
              <uploader theme="orange" v-model="imgList" :maxCount="5" :onSuccess="uploadImg"></uploader>
            </ul>
          </div>

          <div class="goods-content__infomation">
            <div class="van-hairline--bottom info__box">
              <span>商品名称</span>
              <input class="weui-input desc"
                     @input="inputEvent"
                     style="color:#666666;"
                     v-model="goodsInfo.name"
              >
            </div>
            <div class="van-hairline--bottom info__box">
              <span>网店售价</span>

              <input class="weui-input desc"
                     style="color:#666666;"
                     type="number"
                     v-model="itemDetail.oriPrice"
                     @input="inputMoney(false)"
                     v-if="goodSku.isSku"
                     placeholder="请输入网店售价"
              >
              <input class="weui-input desc"
                     style="color:#666666;"
                     v-model="goodsInfo.onlinePrice"
                     type="number"
                     @input="inputMoney(true)"
                     placeholder="请输入网店售价"
                     v-else
              >
            </div>
            <div class="van-hairline--bottom info__box">
              <span>门店售价</span>
              <!-- <span class="desc"
                      style="color:#666666;">279.50</span> -->
              <!-- @input="inputEvent" -->

              <span class=" desc"
                    style="color:#666666;"
                    v-if="goodSku.isSku"
              >
                {{ currency(itemDetail.price) }}
              </span>
              <span class=" desc"
                    style="color:#666666;"
                    v-else
              >
                {{ currency(goodsInfo.price) }}
              </span>
            </div>

            <div class="van-hairline--bottom info__box">
              <span>描述</span>
              <input class="weui-input desc"
                     @input="inputEvent"
                     style="color:#666666;"
                     v-model="goodsInfo.details"
                     placeholder="输入对商品的描述"
              >
            </div>
            <div class="van-hairline--bottom info__box">
              <span>网店数量</span>
              <input class="weui-input desc"
                     @input="inputEvent"
                     type="number"
                     v-model="goodSku.gsQuantity"
                     style="color:#666666;"
                     v-if="goodSku.isSku"
              >
              <input class="weui-input desc"
                     @input="inputEvent"
                     type="number"
                     v-model="goodsInfo.quantity"
                     style="color:#666666;"
                     v-else
              >
            </div>
            <div class="van-hairline--bottom info__box"
                 v-if="!isAdd"
            >
              <span>运费</span>
              <!-- <span class="desc"
                      style="color:#666666;">满100.00包邮</span> -->
              <input class="weui-input desc"
                     @input="inputEvent"
                     type="number"
                     style="color:#666666;"
                     v-model="goodsInfo.expressTemplateName"
              >
            </div>
            <span class="showMore"
                  @click="showContent(2)"
                  v-if="showContentArea === 1"
            >
              展示更多商品信息<i class="iconfont2 icon-xiala2-01 showmore-icon"></i>
            </span>
          </div>

          <div v-if="showContentArea !== 1"
               :class="[isAdd ? 'goods-content__detail noColor' : 'goods-content__detail']"
          >
            <div class="goods-detail">
              <div class="van-hairline--bottom info__box">
                <span>商品条码</span>
                <input class="weui-input desc"
                       @input="inputEvent"
                       style="color:#666666;"
                       :readonly="!isAdd"
                       v-model="goodsDetail.gBarcode"
                >
              </div>
              <div class="van-hairline--bottom info__box">
                <span>商品分类</span>
                <span class="desc"
                      style="color:#666666;"
                      v-if="isAdd"
                >默认分类</span>
                <span class="desc"
                      style="color:#666666;"
                      v-else
                >{{ goodsInfo.categoryName }} {{ goodsInfo.subcategoryName }}</span>
              </div>
              <div class="van-hairline--bottom info__box">
                <span>商品进价</span>

                <input class="weui-input desc"
                       @input="inputCost"
                       style="color:#666666;"
                       v-if="isAdd"
                       type="number"
                       v-model="goodsDetail.gCostPrice"
                >
                <span v-else>
                  <span class="desc"
                        style="color:#666666;"
                        v-if="goodSku.isSku"
                  >{{ currency(itemDetail.gsCostPrice) }}</span>
                  <span class="desc"
                        style="color:#666666;"
                        v-else
                  >{{ currency(goodsDetail.gCostPrice) }}</span>
                </span>
              </div>
              <div class="van-hairline--bottom info__box"
                   v-if="!isAdd"
              >
                <span>颜色尺码</span>
                <span class="desc"
                      style="color:#BABABA;"
                      v-if="goodSku.Attributes"
                >{{ goodSku.Attributes[0].gaVName }}{{ goodSku.Attributes[1].gaVName }}</span>
                <span class="desc"
                      style="color:#BABABA;"
                      v-else
                >--</span>
              </div>
            </div>
          </div>
          <span class="showMore"
                @click="showContent(3)"
                v-if="showContentArea === 2"
          >
            展示更多商品信息<i class="iconfont2 icon-xiala2-01 showmore-icon"></i>
          </span>
          <div class="goods-content__remark"
               v-if="showContentArea === 3"
          >
            <div class="van-hairline--bottom info__box">
              <span>备注</span>
              <input class="weui-input desc"
                     @input="inputEvent"
                     style="color:#666666;"
                     :readonly="!isAdd"
                     placeholder="请输入备注"
                     v-model="goodsDetail.gRemark"
              >
            </div>
            <div class="van-hairline--bottom info__box">
              <span>商品规格</span>
              <input class="weui-input desc"
                     @input="inputEvent"
                     style="color:#666666;"
                     :readonly="!isAdd"
                     placeholder="请输入商品规格"
                     v-model="goodsDetail.gSpecification"
              >
            </div>
          </div>
          <!-- <div class="cv-box" @click="goToCv">
            <span>浏览详情</span>
            <span class="cv-user">
              <span style="color:#666666;">128</span>
              <span style="color:#999999;">浏览顾客</span>
            </span>
            <span class="cv-times">
              <span style="color:#666666;">320</span>
              <span style="color:#999999;">浏览次数</span>
            </span>
            <span class="icon-font"><van-icon name="play" /></span>
          </div> -->
        </div>
      </div>

    </div>
    <!-- 数字键盘 -->
    <van-loading size="24px" vertical v-if="saveImgLoading">加载中...</van-loading>
    <van-dialog v-model="showTip" show-cancel-button confirmButtonText="立即开通" confirmButtonColor="#FF6A3C" @confirm="openNow()">
      <div>
        <img class="tip-image"
             src="../../assets/images/miniprogram/mini-top-banner@2x.png"
             alt=""
        >

        <div
          class="font-15 word-box"
        >
          <p class="content text-center">老板，您目前为小程序体验版</p>
          <p class="content text-center ">此功能需要开通小程序才可使用</p>
        </div>
      </div>
    </van-dialog>
    <!-- <c-dialog v-if="showTip">

      </c-dialog> -->

    <van-dialog v-model="showTimes" show-cancel-button confirmButtonText="立即开通" confirmButtonColor="#FF6A3C" @confirm="openNow()">
      <div>
        <img class="tip-image"
             src="../../assets/images/miniprogram/mini-top-banner@2x.png"
             alt=""
        >

        <div
          class="font-15 word-box"
        >
          <p class="content text-center">老板，您目前为小程序体验版</p>
          <p class="content text-center ">此功能需要开通小程序才可使用</p>
        </div>
      </div>
    </van-dialog>
    <div v-show="sharedialog"
         class="share__box"
         @click="sharedialog = false"
    >
      <div class="share-content">
        <canvas id="posterHtml"></canvas>
      </div>
      <div class="share-bottom">
        <div class="border-box van-hairline--bottom ">
          <span class="share-title">
            分享商品
          </span>

        </div>
        <div class="share__btn">
          <div @click="shareToWechat">
            <i class="iconfont2 icon-weixin wechat-icon"></i>
            <span>微 信</span>
          </div>
          <div @click="shareToWechatQ">
            <i class="wechatq-icon"></i>
            <span class="pengyouquan">朋友圈</span>
          </div>
          <div @click="saveImg">
            <i class="iconfont2 icon-tupian  saveimg-icon"></i>
            <span>保存图片</span>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer style="z-index:0;">
      <div class="bottom__btn">
        <van-button color="#17BF62" @click="save" class="save__btn">保存</van-button>
        <van-button color="#FF6A3C" @click="share" class="share__btn">发布</van-button>
      </div>
    </template>
  </page-view>
</template>
<style lang="scss" scoped>
@import './index.scss';
</style>
<script>
import { imgFullFix, imgJudgeUrl } from '@/utils/img'
import miniProgramModel from '../../model/miniprogram/index'
// import html2canvas from 'html2canvas'
import { mapGetters } from 'vuex'
import { downloadPic } from '../../utils/canvas'
// import ScrollView from '@/components/scroll-view'
import PageView from '@/layouts/PageView'
import Uploader from '@/components/uploader'
import NavBar from '@/components/nav-bar'
import api from '@/tools/api-tools'
import { mainWeb } from '@/config/net'
import { Button, Loading, Dialog, Image, Icon, Toast } from 'vant'
import axios from 'axios'
export default {
  data () {
    return {
      refuseReq: false, // 阻止点击保存后再点击发布产生的重复请求
      posterImg: '', // 最终生成的海报图片
      qrCodeImg: '', // 二维码图片
      posterHtmlBg: '', // 分享商品图
      showTip: false, // 限制功能弹窗
      showTimes: false, // 限制次数弹窗
      sharedialog: false, // 分享dialog
      isAdd: false, //  列表页navbar点击新增商品
      imgList: [
      ], // 小程序图片
      goodsInfo: {
        onlinePrice: ''
      }, // 网店商品详情
      goodsDetail: {
        gCostPrice: ''
      }, // 商品详情
      goodSku: {}, // 从列表穿过来的值
      showContentArea: 1, // 展开信息开关
      saveImgURL: null, // 保存图片地址
      time: null, // 计时器 id
      itemDetail: {}, // sku商品详情
      canvas: null, // canvas
      uploadOption: {}, // 上传图片设置
      miniProgramInfo: {},
      saveImgLoading: false,
      loadingText: '加载中'
    }
  },
  components: { Uploader, NavBar, PageView, [Image.name]: Image, [Button.name]: Button, [Loading.name]: Loading, [Dialog.Component.name]: Dialog.Component, [Icon.name]: Icon, [Toast.name]: Toast },
  created () {
  },
  async mounted () {
    if (this.$route.query.goodsId) {
      this.goodSku.goodsId = this.$route.query.goodsId
      this.goodSku.isSku = false
    } else if (this.$route.query.goodsInfo) {
      this.goodSku = JSON.parse(this.$route.query.goodsInfo)
      this.goodSku.isSku = true
    } else if (this.$route.query.isAdd) {
      this.goodSku.isSku = false
      this.goodsInfo.quantity = 999
      this.isAdd = true
    }
    if (this.isAdd) return
    this.getGoodsDetail() // 获取商品详情
    this.getBaseInfo() // 获取小程序地址 电话
    setTimeout(() => {
      this.getMiniAppGoodsDetail() // 获取网店商品详情
    }, 500)
  },
  beforeDestroy () {
  },
  methods: {
    imgJudgeUrl,
    downloadPic,
    imgFullFix,
    uploadImg (value) {
    },
    goToCv () {
      const pic = this.imgList[0] ? this.imgFullFix(this.imgList[0].url, 'large') : ''
      const onlinePrice = this.goodSku.isSku ? this.itemDetail.onlinePrice : this.goodsInfo.onlinePrice
      this.$router.push({
        path: '/goodShare-cv',
        query: {
          goodsPic: pic,
          onlinePrice: onlinePrice,
          goodsName: this.goodsInfo.name
        }
      })
    },
    async getBaseInfo () {
      const data = await miniProgramModel.getBaseInfo()
      this.miniProgramInfo = data.data
    },
    chunk (arr, size) {
      const res = []
      for (let i = 0; i < arr.length; i += size) {
        res.push(arr.slice(i, i + size))
      }
      return res
    },
    // 标准化金额显示处理函数
    currency (val = '') {
      if (Number.isNaN(val)) return '-'
      if (val === '' || val === null) return '0.00'
      const size = 5
      const res = ['', '00']
      try {
        val = val.toString()
        const vals = val.split('.')
        res[0] = this.chunk(vals[0].split('').reverse(), size).map(item => item.reverse()).map(item => item.join('')).reverse().join(',')
        if (vals[1]) res[1] = vals[1].padEnd(2, '0')
      } catch (err) {
        // eslint-disable-next-line no-console
        console.err(err)
      }
      return res.join('.')
    },
    openNow () { // 立即开通
      api.get(`${mainWeb}/Function/LogAction.ashx?type=weblog&pid=221&accId=${this.miniProgramInfo.accountId}`)
      window.location.href = 'http://app-mall.i200.cn/shop_v5.html?redirectUrl=/valueAddedService/detail/136'
    },
    // 获取签名
    blobToFile (theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },
    async getSignature () {
      // canvas图片base64 转 File 对象
      const dataURL = this.canvas.toDataURL('image/png')
      const arr = dataURL.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      let obj = new Blob([u8arr], { type: mime })
      obj = this.blobToFile(obj, 'a.png')
      const formData = new FormData()
      formData.append('file', obj)
      // 获取文件后缀
      const suffix = formData.get('file').type.split('/')[1]
      const data = await miniProgramModel.getSign({ suffix: suffix })
      if (data.code === 0) {
        this.uploadOption.path = data.data.uploadUrl
        formData.append('policy', data.data.policy)
        formData.append('signature', data.data.signStr)
        formData.append('bucketName', 'img-i200')
        this.updateImg(formData)
      }
    },
    // 上传
    updateImg (formData) {
      axios({
        url: 'https://v1.api.upyun.com/img-i200/',
        method: 'POST',
        data: formData,
        processData: false,
        contentType: false
      }).then(res => {
        if (res.data.code === 200) {
          const url = res.data.url
          this.saveImgURL = 'http://img.laoban100.com' + url
          this.saveImgLoading = false
          this.sharedialog = true
        }
      })
    },
    createItemDetail () { // 将网店商品详情和商品详情合并
      let itemList = {}
      let itemList2 = {}
      this.goodsDetail.skuList.forEach(item => {
        if (this.goodSku.gsId === item.gsId) {
          itemList = item
        }
      })
      this.goodsInfo.skus.forEach(item => {
        if (this.goodSku.gsId === item.skuId) {
          itemList2 = item
        }
      })
      this.itemDetail = Object.assign(itemList, itemList2)
      this.itemDetail.oriPrice = this.currency(this.itemDetail.oriPrice)
    },
    shareToWechat () { // 分享至微信
      if (this.saveImgLoading && this.saveImgURL) return
      window.$fe_bridge.shareAction.wechatSharePicture({
        imgUrl: this.saveImgURL
      })
    },
    shareToWechatQ () { // 分享至微信朋友圈
      if (this.saveImgLoading && this.saveImgURL) return
      window.$fe_bridge.shareAction.circleFriendsSharePicture({
        imgUrl: this.saveImgURL
      })
    },
    saveImg () { // 保存图片
      if (this.saveImgLoading && this.saveImgURL) return
      window.$fe_bridge.shareAction.savePicture({
        title: '',
        link: '',
        imgUrl: this.saveImgURL,
        description: ''
      }, () => {
      })
    },
    async getGoodsDetail () { // 获取商品详情
      const data = await miniProgramModel.getGoodsDetail(this.goodSku.goodsId)
      this.goodsDetail = data.data
    },
    insertStr (soure, start, newStr) {
      return soure.slice(0, start) + newStr + soure.slice(start)
    },
    /* eslint-disable */
    async getMiniAppGoodsDetail () { // 获取网店商品详情
      let data = await miniProgramModel.getMiniAppGoodsDetail(this.goodSku.goodsId)
      this.goodsInfo = data // 新接口
      this.goodsInfo.price = this.currency(this.goodsInfo.price)
      this.goodsInfo.onlinePrice = this.currency(this.goodsInfo.oriPrice)
      if (!this.goodsInfo.quantity) this.goodsInfo.quantity = 999
      this.imgList = data.pictures.map((item, index) => {
        return {
          url: imgFullFix(item, 'small'),
        }
      })
      // this.posterHtmlBg = this.imgList[0].src // 获取分享主图
      if (this.goodSku.isSku) {
        this.time = setTimeout(() => {
          this.createItemDetail()
        }, 600)
      }
    },
    inputEvent () { },
    inputMoney (value) {
      if (value) {
        if (this.goodsInfo.onlinePrice) this.goodsInfo.onlinePrice = this.currency(this.goodsInfo.onlinePrice)
      }
      if (!value) {
        if (this.itemDetail.oriPrice) this.itemDetail.oriPrice = this.currency(this.itemDetail.oriPrice)
      }
    },
    inputCost () {
      this.goodsDetail.gCostPrice = this.currency(Number(this.goodsDetail.gCostPrice))
    },
    getBase64Image (img) {
      let canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      let ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, img.width, img.height)
      let ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
      let dataURL = canvas.toDataURL('image/' + ext)
      return dataURL
    },
    async share () { // 发布
    this.saveImgLoading = true
      // 这一步之前是用来防治再次点击重复请求的，但是我没时间改所以先这样 
      // if (!this.refuseReq) {
      await this.save(true)
      // }
      this.posterHtmlBg = this.imgList[0].url
      if (this.posterHtmlBg === '') {
        Toast.fail('请至少上传一张图片')
        this.saveImgLoading = false
        return
      }
      this.posterHtmlBg = this.imgJudgeUrl(this.imgList[0].url, 'large')// 获取分享主图
      if (this.goodSku.isSku && !this.itemDetail.oriPrice && this.itemDetail.oriPrice !== null) {
        Toast.fail('请输入网店售价')
        this.saveImgLoading = false
        return
      }
      if (!this.goodSku.isSku && !this.goodsInfo.onlinePrice && this.goodsInfo.onlinePrice !== null) {
        Toast.fail('请输入网店售价')
        this.saveImgLoading = false
        return
      }
      let data = await miniProgramModel.getShareLimit() // 获取是否抵达分享上限
      let img = await miniProgramModel.getShareQcode(this.goodSku.goodsId) // 获取分享的小程序码
      this.qrCodeImg = img.qrCode
      if (!data.data) {
        await miniProgramModel.updateMiniprogram(this.goodSku.goodsId)
        this.time = setTimeout(() => {
          this.createPoster()
          // this.newShare()
        }, 1000)
        return
      }
      this.saveImgLoading = false
      this.showTimes = true
    },
    checkInfo () {
      if (this.posterHtmlBg === '') {
        Toast.fail('')
      }
    },
    newShare () {
      this.saveImgLoading = false
      // if (!window.$fe_bridge) return
      if (!this.checkInfo()) return
      let goodsInfo = {
        qrCodeImg: this.qrCodeImg,
        goodsImg: this.posterHtmlBg,
        name: this.goodsInfo.name,
        price: this.goodSku.isSku ? this.itemDetail.oriPrice : this.goodsInfo.onlinePrice,
        phone: this.miniProgramInfo.accountMobile,
        shopName: this.getStoreName,
        address: this.miniProgramInfo.addressDetail || ''
      }
      window.$fe_bridge.shareAction.releaseGoods(goodsInfo, () => {
      })
    },
    createPoster () { // 生成canvas海报
      let canvas = document.getElementById('posterHtml')
      const ctx = canvas.getContext('2d')
      // html2canvas(imgHtml, {
      //   useCORS: true,
      //   dpi: 192,
      //   scale: window.devicePixelRatio
      // }).then(canvas => {
      //   this.canvas = canvas
      //   this.getSignature()
      //   clearTimeout(this.time)
      // })
      let shopInfo = {
        phone: this.miniProgramInfo.accountMobile,
        shopName: this.getStoreName,
        address: this.miniProgramInfo.addressDetail || ''
      }
      let goodsInfo = {
        name: this.goodsInfo.name,
        price: this.goodSku.isSku ? this.itemDetail.oriPrice : this.goodsInfo.onlinePrice
      }
      this.downloadPic(canvas, ctx, this.posterHtmlBg, this.qrCodeImg, shopInfo, goodsInfo)
            // this.sharedialog = true
      setTimeout(() => {
        this.canvas = canvas
        this.getSignature()
        clearTimeout(this.time)
      }, 500)
    },
    async goodsDetailSave () {
      let imgList = Array.from(this.imgList)
      let updateImgList = []
      imgList.forEach(item => {
        updateImgList.push(item.url)
      })
      for (let i = updateImgList.length - 1; i >= 0; i--) {
        if (!updateImgList[i]) {
          updateImgList.splice(i, 1)
        }
      }
      let fixImgList = []
      updateImgList.map(item => {
        if (item.indexOf('!') > -1) {
          item = item.substring(0, (item.indexOf('!'))) // 将!后面部分剔除
          fixImgList.push(item)
        } else {
          fixImgList.push(item)
        }
      })
      let params = {
        IsExtend: 0,
        SupplierId: '0',
        Unit: '',
        UnitId: 0,
        gBarcode: this.goodsDetail.gBarcode || 0,
        gCostPrice: this.goodsDetail.gCostPrice || 0,
        gDiscount: 0,
        gMaxID: 0,
        gMaxName: '默认分类',
        gMinID: 0,
        gMinName: '',
        gName: this.goodsInfo.name || '',
        gPicUrls: fixImgList,
        gPrice: this.goodsInfo.onlinePrice || 0,
        gRemark: this.goodsDetail.gRemark || '',
        gSpecification: this.goodsDetail.gSpecification,
        gid: this.goodSku.goodsId,
        isService: 0
      }
      await miniProgramModel.saveGoodsDetail(params)
    },
    async postGoods () {
      if (!this.goodsInfo.name) {
        Toast.fail('请输入商品名称')
        return
      }
      if (!this.goodsInfo.onlinePrice) {
        Toast.fail('请输入网店售价')
        return
      }
      if (this.imgList.length === 0) {
        Toast.fail('请至少上传一张图片')
        return
      }
      let params = {
        gName: this.goodsInfo.name,
        gQuantity: this.goodsInfo.quantity === 0 ? 1 : this.goodsInfo.quantity,
        gPrice: this.goodsInfo.onlinePrice,
        gMaxID: 0,
        gMinID: 0
      }
      let data = await miniProgramModel.addGoods(params)
      if (data.data && data.data !== null) {
        this.goodSku.goodsId = data.data
        this.goodsDetailSave()
        return true
      } else if (data.message.length) {
        Toast.fail(data.message)
        return false
      }
    },
    async save (flag = false) { // 保存
      if (this.isAdd) {
        let result = await this.postGoods()
        if (!result) return
      }
      let imgList = Array.from(this.imgList)
      let updateImgList = []
      imgList.forEach(item => {
        updateImgList.push(item.url)
      })
      for (let i = updateImgList.length - 1; i >= 0; i--) {
        if (!updateImgList[i]) {
          updateImgList.splice(i, 1)
        }
      }
      let fixImgList = []
      updateImgList.map(item => {
        if (item.indexOf('!') > -1) {
          item = item.substring(0, (item.indexOf('!'))) // 将!后面部分剔除
          fixImgList.push(item)
        } else {
          fixImgList.push(item)
        }
      })
      let skuList = []
      if (this.goodSku.isSku) {
        this.goodsInfo.skus.forEach(item => {
          if (this.goodSku.gsId === item.skuId) {
            item.quantity = Number(this.goodSku.gsQuantity)
            item.oriPrice = Number(this.itemDetail.oriPrice)
            if (item.oriPrice === null) item.oriPrice = this.itemDetail.price
            skuList.push(item)
          }
        })
      }
      if (this.goodsInfo.onlinePrice < 0) {
        Toast.fail('售价不可为负数')
        return
      }
      let params = {
        details: this.goodsInfo.details,
        categoryName: this.goodsInfo.categoryName,
        isSku: this.goodSku.isSku,
        id: this.goodSku.goodsId,
        expressBaseFeeDesc: this.goodsInfo.expressBaseFeeDesc,
        categoryId: this.goodsInfo.categoryId,
        status: 1,
        expressTemplateName: this.goodsInfo.expressTemplateName,
        pictures:fixImgList.map(v=> v.replace(/\/\/img.i200.cn/g,'')),
        oriPrice: this.goodsInfo.onlinePrice,
        dctPrice: this.goodsInfo.dctPrice,
        describe: this.goodsInfo.describe,
        aliasName: this.goodsInfo.aliasName,
        discounts: this.goodsInfo.discounts,
        onlinePrice: null,
        price: this.goodsDetail.price,
        isService: this.goodsInfo.isService,
        subcategoryName: this.goodsInfo.categoryName,
        name: this.goodsInfo.name,
        specification: this.goodsInfo.specification,
        skus: this.goodSku.isSku ? skuList : [],
        subCategoryId: this.goodsInfo.subCategoryId,
        subcategoryName: this.goodsInfo.subcategoryName,
        quantity: this.goodSku.isSku ? Number(this.goodSku.gsQuantity) || 0 : Number(this.goodsInfo.quantity) || 0
      }
      let data = await miniProgramModel.saveChange(params)
      if (data === true) { 
        this.refuseReq = true
        Toast.success('保存成功')
        }
      if (flag) return
    },
    async addGoodsDetailImg () { // 多个upload 因为商品详情接口暂且隐藏
      // let data = await miniProgramModel.getShareLimit()
      // if (!data.data) {
      //   if (!this.goodsDetailList[0].src) {
      //     this.$refs.inputdetail1.$refs.input.click()
      //     return
      //   }
      //   if (!this.goodsDetailList[1].src) {
      //     this.$refs.inputdetail2.$refs.input.click()
      //     return
      //   }
      //   if (!this.goodsDetailList[2].src) {
      //     this.$refs.inputdetail3.$refs.input.click()
      //     return
      //   }
      //   if (!this.goodsDetailList[3].src) {
      //     this.$refs.inputdetail4.$refs.input.click()
      //     return
      //   }
      //   if (!this.goodsDetailList[4].src) {
      //     this.$refs.inputdetail5.$refs.input.click()
      //     return
      //   }
      //   if (!this.goodsDetailList[5].src) {
      //     this.$refs.inputdetail6.$refs.input.click()
      //     return
      //   }
      // }
      this.showTip = true
    },
    showContent (value) {
      this.showContentArea = value
      this.$nextTick(() => {
        this.$refs.scroll.refresh()
      })
    }
  },
  computed: {
    ...mapGetters({
      getStoreName: 'userInfo/getStoreName'
    }),
    // goodsDetailShow () {
    //   if (this.goodsDetailList[0].src && this.goodsDetailList[1].src && this.goodsDetailList[2].src && this.goodsDetailList[3].src && this.goodsDetailList[4].src && this.goodsDetailList[5].src) {
    //     return false
    //   }
    //   return true
    // }
  },
  watch: {
  }
}
</script>
<style lang="scss">
.goods-containner {
  .weui-dialog__bd:first-child {
    padding-bottom: 0 !important;
  }
}
</style>
