const getCookie = name => {
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  const arr = document.cookie.match(reg)
  return arr ? arr[2] : null
}

const setCookie = (key, value) => {
  document.cookie = key + '=' + value
}
export { getCookie, setCookie }
// eslint-disable-next-line no-multiple-empty-lines

