import numeral from 'numeral'
import Vue from 'vue'

const baseFormat = (input, format = '0,0.00') => {
  // input [undefined,null,string,number]
  if (input === undefined || input === null) return input
  // convert number to string
  input = String(input).replace(/,/g, '')
  // convert stand string to number
  const value = Number.parseFloat(input)
  if (Number.isNaN(value)) {
    return input
  }
  const result = numeral(value).format(format)
  return result === 'NaN' ? input : result
}
const currencyFormat = (num) => {
  let isNegative = false
  if (num < 0) {
    isNegative = true
  }
  num = Math.abs(num)
  let formattedNumber = null
  if (num >= 100000000) {
    formattedNumber = (num / 100000000).toFixed(1).replace(/.0$/, '') + '亿'
  } else if (num >= 10000000) {
    formattedNumber = (num / 10000000).toFixed(1).replace(/.0$/, '') + '千万'
  } else if (num >= 10000) {
    formattedNumber = (num / 10000).toFixed(1).replace(/.0$/, '') + '万'
  } else {
    formattedNumber = num
  }
  if (isNegative) {
    formattedNumber = '-' + formattedNumber
  }
  return formattedNumber
}

Vue.filter('currency', baseFormat)

Vue.filter('quantity', (input) => {
  return baseFormat(input, '0,0.0000')
})

Vue.filter('percentNumber', (input) => {
  return baseFormat(input, '0.00')
})
Vue.filter('numberToCurrency', (input) => {
  return currencyFormat(input)
})
