import PermissionConfig from '@/model/permission/config'
import PositionData from '@/model/permission/select-position/form'

const operationStatusEnum = {
  update: 'updatePosition',
  create: 'createPosition',
  bind: 'bindPosition'
}
export default {
  namespaced: true,
  state: {
    positionId: null,
    positionObj: {}, // 选择的权限对象
    permissionConfig: {},
    permissionConfigInstance: null,
    operationStatus: '', // updatePosition更新职位模板、createPosition创建职位模板、bindPosition员工职位绑定
    selectList: [] // 选择权限列表
  },
  getters: {
    getPermissionConfig (state) {
      return state.permissionConfig
    },
    getPositionObj (state) {
      return state.positionObj
    },
    getSelectList (state) {
      return state.selectList
    },
    getConfigInstance (state) {
      return state.permissionConfigInstance
    }
  },
  actions: {
    operationStatusIsUpdate ({ state }) {
      state.operationStatus = operationStatusEnum.update
    },
    operationStatusIsCreate ({ state }) {
      state.operationStatus = operationStatusEnum.create
    },
    operationStatusIsBind ({ state }) {
      state.operationStatus = operationStatusEnum.bind
    },
    setPositionId ({ state }, positionId) {
      state.positionId = positionId
    },
    setPositionObj ({ state }, { id, displayName }) {
      state.positionObj = {
        id,
        displayName
      }
    },
    loadPermissionConfig ({ commit, state }, { positionId = null } = {}) {
      // 保存后才可执行清理
      if (Object.keys(state.permissionConfig).length) return
      const pConfig = new PermissionConfig()
      state.permissionConfigInstance = pConfig
      if (positionId) {
        pConfig.loadPositionPermissions(positionId).then(data => {
          commit('setPermissionConfig', data.permissions)
        })
      } else {
        return pConfig.loadPermissions().then(data => {
          commit('setPermissionConfig', data.permissions)
        })
      }
    },
    permissionIds ({ state }) {
      const instance = state.permissionConfigInstance
      if (!instance) return []
      return instance.permissionIds()
    },
    cleanPermissionConfig ({ state }) {
      // 清理配置对象
      state.permissionConfig = {}
    },
    cleanPositionObj ({ state }) {
      state.positionObj = {}
    },
    submitData ({ state }) {
    },
    loadSelectList ({ commit }) {
      new PositionData().dataSchema().then(data => {
        commit('setSelectList', data)
      })
    }
  },
  mutations: {
    setPermissionConfig (state, data) {
      state.permissionConfig = data
    },
    setSelectList (state, data) {
      state.selectList = data
    }
  }
}
