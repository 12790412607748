import * as urls from '@/config/net'
import api from '@/tools/api-tools'

// namespace = settings
export default {
  namespaced: true,
  state: {
    thirdPaymentOpen: false
  },
  getters: {
    getThirdPaymentOpen (state) {
      return state.thirdPaymentOpen
    }
  },
  actions: {
    loadSettings ({ commit }) {
      api.get(`${urls.apiUrl}/sales/payment/is-third-payment-open`).then(res => {
        commit('changeThirdPaymentOpen', res.data || false)
      })
    }
  },
  mutations: {
    changeThirdPaymentOpen (state, data) {
      state.thirdPaymentOpen = data
    }
  }
}
