<template>
  <div class="status-box">
    <div class="status-content"
         v-if="orderInfo.currentStatus === 0 || orderInfo.currentStatus === 1"
    >
      <span class="status-title">订单提交成功</span>
      <span class="status-word">快递员于今天{{ orderInfo.pickupStartTime }}-{{ orderInfo.pickupEndTime }}联系您，上门取件</span>
      <div class="cancel-btn"
           @click="cancelOrder"
      >取消订单</div>
    </div>
    <!-- v-if="orderInfo.currentStatus === 2" -->
    <div class="status-content"
         v-if="orderInfo.currentStatus === 2"
    >
      <span class="status-title">等待快递员揽件</span>
      <span class="status-word">快递员于今天{{ orderInfo.pickupStartTime }}-{{ orderInfo.pickupEndTime }}联系您，上门取件</span>
      <div style="display:flex;justify-content:space-around;width:100%;">
        <!-- <van-button type="default"
                    class="content-btn">联系快递员</van-button> -->
        <div class="cancel-btn"
             @click="cancelOrder"
        >取消订单</div>
      </div>
    </div>
    <div class="status-content"
         v-if="orderInfo.currentStatus === 99"
    >
      <div class="status-success">
        <van-radio checked-color="#f44"
                   style="color:#333333;"
        >
          <van-icon name="cross"
                    slot="icon"
                    color="white"
                    style="background-color:#f44;border-color:#f44;"
          />
          订单已取消</van-radio>
      </div>
      <div class="order-number">
        <van-cell :title="orderInfo.expressCompanyName"
                  :value="orderInfo.expressNumber"
                  style="border:none;"
        />
        <van-cell title="订单编号"
                  :value="orderInfo.orderId"
        />
      </div>
    </div>
    <div class="status-content"
         v-if="orderInfo.currentStatus >= 10"
    >
      <div class="status-success">
        <van-radio checked-color="#4FD77C"
                   style="color:#333333;"
        >订单已发货</van-radio>
      </div>
      <div class="order-number">
        <van-cell :title="orderInfo.expressCompanyName"
                  :value="orderInfo.expressNumber"
                  style="border:none;"
        />
        <van-cell title="订单编号"
                  :value="orderInfo.orderId"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ExpressModel from '../../../model/express/index'
export default {
  name: 'OrderStatus',
  mixins: [],
  components: {},
  props: {
    orderInfo: {
      type: Object,
      default: () => {}
    },
    orderId: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      expressCompany: '顺丰快递',
      orderNumber: 49796414901795,
      goodsOrderNumber: 68236982682652
    }
  },
  computed: {
  },
  watch: {
  },
  created () { },
  mounted () {
  },
  destroyed () { },
  methods: {
    async cancelOrder () {
      const data = await ExpressModel.goToCancel(this.orderId)
      if (data.status) {
        this.$emit('orderCancel', true)
        // this.$toast('取消成功')
        return
      }
      this.$toast(data.message)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../../src/assets/scss/base/_mixin';
.status-box {
  background-color: white;
  width: px2rem(690px);
  margin: px2rem(30px) auto;
}
.status-content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  span {
    display: block;
  }
  .status-title {
    color: #333333;
    font-size: px2rem(32px);
    padding-top: px2rem(60px);
  }
  .status-word {
    color: #3894ff;
    font-size: px2rem(26px);
    padding-top: px2rem(32px);
    padding-bottom: px2rem(23px);
  }
  .order-btn {
    height: px2rem(48px);
    color: #666666;
    font-size: px2rem(24px);
    line-height: px2rem(48px);
    text-align: center;
    margin-bottom: px2rem(60px);
  }
  .status-success {
    margin: px2rem(63px) auto;
  }
  .order-number {
    width: px2rem(660px);
    margin-left: px2rem(30px);
    margin-top: px2rem(10px);
  }
}

.cancel-btn {
  width: px2rem(196px);
  height: px2rem(54px);
  border: 1px solid #dddddd;
  text-align: center;
  line-height: px2rem(54px);
  margin-bottom: px2rem(60px);
  font-size: px2rem(24px);
}
.content-btn {
  height: px2rem(48px);
  border: 1px solid #dddddd;
  text-align: center;
  line-height: px2rem(48px);
  margin-bottom: px2rem(60px);
  font-size: px2rem(20px);
}
</style>
