import api from '@/tools/api-tools'
import { apiUrl, mscUrl } from '@/config/net'

export default class ExpressModel {
  /**
   * 获取快递公司
   */
  static getExpressCompany () {
    return api.get(`${mscUrl}/v0/express/companies`).then(res => res.data)
  }

  /**
   * 获取发件人列表
   */
  static getSenderList () {
    return api.get(`${mscUrl}/v0/express/senderlist`).then(res => res.data)
  }

  /**
   * 发件
   */
  static goToSend (params) {
    return api.post(`${mscUrl}/v0/express/send`, params).then(res => res.data)
  }

  /**
   * 取消订单
   */
  static goToCancel (orderId) {
    return api.post(`${mscUrl}/v0/express/cancel`, { orderid: orderId, Reason: '其他' }).then(res => res.data)
  }

  /**
   * 获取订单状态
   */
  static getSendInfo (orderId) {
    return api.get(`${mscUrl}/v0/express/info?orderid=${orderId}`).then(res => res.data)
  }

  /**
   * 直接发货 http://msc-api.laoban100.com/orders-management/orders/delivery
   */
  static dirctSend (params) {
    return api.post(`${mscUrl}/orders-management/orders/delivery`, params).then(res => res.data)
  }

  /**
   * 获取发货人信息
   * http://webapi.laoban100.com/
   */
  static getEeceiverInfo (orderId) {
    return api.get(`${apiUrl}/mobilecase/order/${orderId}`).then(res => res.data)
  }
}
