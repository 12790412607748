import axios from 'axios'
import { Toast } from 'vant'
import { apiTimeout } from '../config/net'
import { getCookie } from './cookie'

let token
if (process.env.NODE_ENV === 'development') {
  token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50SWQiOjMyOTU5LCJvcGVyYXRvcklkIjozNzAzNCwidGltZXN0YW1wIjoiMTYwNzkyMTY5Mzg4MyIsIm1vZGUiOjEsInNvdXJjZSI6Ik1CIn0._rvg3FLvc3dv4ATJXY_PP0QM97VbFVvD8V--BaVEa-Y'
} else {
  token = getCookie('token') || getCookie('i200_token')
}
const apiConfig = {
  timeout: apiTimeout,
  headers: {
    token
  }
}
const _axios = axios.create(apiConfig)
const _saxios = axios.create(apiConfig)

// Add a request interceptor
_axios.interceptors.request.use(function (config) {
  Toast.loading({
    message: '加载中',
    duration: 0
  })
  return config
}, function (error) {
  Toast.clear()
  return Promise.reject(error)
})

// Add a response interceptor
_axios.interceptors.response.use(function (response) {
  Toast.clear()
  return response
}, function (error) {
  if (error.response.status === 400 || error.response.status === 500) {
    Toast({
      type: 'text',
      message: `生意专家暂时没有办法识别，您输入的信息！`,
      duration: 2000
    })
  } else {
    Toast.clear()
  }
  return Promise.reject(error)
})

export default {
  // 静默get，无loading
  sget (url, query) {
    return _saxios.get(url, query)
  },
  get (url, query) {
    return _axios.get(url, query)
  },
  // 桥通讯
  bget (url, query) {
    if (process.env.NODE_ENV === 'development') return this.get(url, query)
    return new Promise(resolve => {
      Toast.loading({
        message: '加载中',
        duration: 0
      })
      window.$fe_bridge.session.send('get', url, {}, res => {
        Toast.clear()
        resolve({ data: res })
      })
    })
  },
  // 静默post，无loading
  spost (url, data) {
    return _saxios.post(url, data)
  },
  // 桥通讯
  post (url, data) {
    return _axios.post(url, data)
  },
  bpost (url, data) {
    if (process.env.NODE_ENV === 'development') return this.post(url, data)
    return new Promise(resolve => {
      Toast.loading({
        message: '加载中',
        duration: 0
      })
      window.$fe_bridge.session.send('post', url, data, res => {
        Toast.clear()
        resolve({ data: res })
      })
    })
  },
  delete (url) {
    return _axios.delete(url)
  },
  put (url, data) {
    return _axios.put(url, data)
  }
}
