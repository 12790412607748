<template>
  <page-view style="background-color:#faf7fa;">
    <!-- <c-page class="goods-containner"> -->
    <template v-slot:nav>
      <nav-bar title="会员详情" left-arrow></nav-bar>
    </template>
    <div style="background-color:#faf7fa;">
      <!-- <scroll-view height="fullView" ref="scroll" style="background-color:#faf7fa;"> -->
      <div class="memberdetail-box__all">
        <div class="memberdetail-box__header">
          <div>
            <avatar shape="square" class="header__avatar" :avatarImage="imgFullFix(memberDetailInfo.portrait, 'small')" :avatarName="memberDetailInfo.name" />
            <!-- <img :src="imgFullFix(memberDetailInfo.portrait)" class="header__avatar" /> -->
          </div>
          <div class="header__info">
            <span class="userinfo">
              <span class="username">
                <span style="float:left;">{{ memberDetailInfo.name }}</span>
                <van-tag round class="discount">{{ memberBaseInfo.memberRankDiscount }}折</van-tag>
              </span>
              <span class="usercardid">{{ memberBaseInfo.memberRankName }} | 卡号：{{ memberDetailInfo.memberNo }}</span>
            </span>
          </div>
          <div class="header__btn">
            <span class="userdetailbtn">基本信息<i class="iconfont2 icon--rightarrow rightarrow"></i></span>
          </div>
        </div>
        <div class="memberdetail-box__all">
          <div class="huiyuan-box">
            <span class="huiyuan-box__content">
              <span class="huiyuan-icon">
                <img src="../../assets/images/miniprogram/huangguan.png" class="huiyuan-icon" />
              </span>
              <span class="huiyuan-rank">{{ memberBaseInfo.memberRankName }}</span>
            </span>
          </div>
          <div class="huiyuan-all">
            <span class="huiyuan-info">
              <span class="info-money">3</span>
              <span class="info-word">购物次数</span>
            </span>
            <span class="huiyuan-info">
              <price
                :price="memberBaseInfo.memberSalesMoney"
                :needSymbol="true"
                :thousands="false"
                class="info-money"
              />
              <span class="info-word">累计消费</span>
            </span>
            <span class="huiyuan-info">
              <span class="info-money">4528</span>
              <span class="info-word">浏览记录</span>
            </span>
          </div>
        </div>
        <div class="doubt-box" v-if="memberBaseInfo.memberDebt">
          <span>欠款总额</span>
          <span>¥{{ memberBaseInfo.memberDebt }}</span>
        </div>
        <div class="cv-history">
          <span class="color-box"></span>
          <span class="history-word">该会员新增8条商品浏览记录，新增2位推荐会员</span>
          <div class="header__btn__bottom">
            <span class="userdetailbtn__bottom">查看详情<i class="iconfont2 icon--rightarrow rightarrow"></i></span>
          </div>
        </div>
        <div class="memberdetail-box__cell">
          <van-cell-group>
            <van-cell :value="memberBaseInfo.memberIntegral" is-link>
              <template slot="title">
                <img src="../../assets/images/miniprogram/point.png" class="point-icon" />
                <span class="custom-title">会员积分</span>
              </template>
            </van-cell>
            <van-cell value="内容" is-link>
              <template slot="title">
                <img src="../../assets/images/miniprogram/coupon.png" class="point-icon" />
                <span class="custom-title">优惠券</span>
              </template>
            </van-cell>
            <van-cell :value="memberBaseInfo.memberBalance" is-link>
              <template slot="title">
                <img src="../../assets/images/miniprogram/money.png" class="point-icon" />
                <span class="custom-title">储值余额</span>
              </template>
            </van-cell>
            <van-cell :value="timeCard" is-link>
              <template slot="title">
                <img src="../../assets/images/miniprogram/card.png" class="point-icon" />
                <span class="custom-title">计次卡</span>
              </template>
            </van-cell>
            <van-cell is-link @click="goToCv">
              <template slot="title">
                <img src="../../assets/images/miniprogram/history.png" class="point-icon" />
                <span class="custom-title">服务记录</span>
              </template>
            </van-cell>
            <van-cell is-link @click="goToMessage">
              <template slot="title">
                <img src="../../assets/images/miniprogram/message.png" class="point-icon" />
                <span class="custom-title">会员消息</span>
              </template>
            </van-cell>
          </van-cell-group>
        </div>

        <div class="memberdetail-box__allinfo">
          <van-cell-group>
            <van-cell title="用户来源">
              <template slot="right-icon">
                <span style="color:#999999;" class="time">2020-04-09</span>
                <span style="color:#666666;">会员卡转介绍</span>
              </template>
            </van-cell>
            <van-cell title="推荐人">
              <template slot="right-icon">
                <img src="../../assets/images/miniprogram/message.png" class="point-icon" />
                <span class="user-name">休米</span>
              </template>
            </van-cell>
            <van-cell is-link>
              <template slot="title">
                <span>推荐详情</span>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
        <div class="btn__box">
          <div class="password">会员消费密码</div>
          <div class="add__sale">添加销售</div>
        </div>
      </div>
    </div>
    <!-- </scroll-view> -->
    <!-- </c-page> -->
  </page-view>
</template>
<style lang="scss" scoped>
@import './index.scss';
</style>
<script>
import MemberDetailMondel from '../../model/member-detail/index'
import PageView from '@/layouts/PageView'
import Price from '@/components/price'
import Avatar from '@/components/avatar'
import NavBar from '@/components/nav-bar'
import { imgFullFix } from '@/utils/img'
import { Cell, CellGroup, Tag } from 'vant'
export default {
  data () {
    return {
      timeCard: '',
      memberDetailInfo: {},
      memberBaseInfo: {}
    }
  },
  components: { NavBar, PageView, [Cell.name]: Cell, [CellGroup.name]: CellGroup, Avatar, Price, [Tag.name]: Tag },
  created () {
  },
  mounted () {
    this.memberDetailInfo = this.$route.query
    this.getTimeCardList()
    this.getInfo()
  },
  beforeDestroy () {
  },
  methods: {
    imgFullFix,
    goToMessage () {
      this.$router.push({
        path: '/member-message'
      })
    },
    goToCv () {
      this.$router.push({
        path: '/member-cv',
        query: {
          id: this.memberDetailInfo.id,
          name: this.memberDetailInfo.name,
          portrait: this.memberDetailInfo.portrait,
          recommandSaleAmount: this.memberDetailInfo.recommandSaleAmount,
          memberNo: this.memberDetailInfo.memberNo,
          recommandCount: this.memberDetailInfo.recommandCount,
          discount: this.memberBaseInfo.memberRankDiscount,
          memberRankName: this.memberBaseInfo.memberRankName
        }
      })
    },
    async getTimeCardList () {
      const data = await MemberDetailMondel.getMemberCardTime(this.$route.query.id)
      this.timeCard = data.count + '张'
    },
    async getInfo () {
      const data = await MemberDetailMondel.getMemberDetail(this.$route.query.id)
      this.memberBaseInfo = data.data
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
</style>
