const device = (function () {
  /* eslint-disable */
  var device = {}
  var ua = navigator.userAgent
  var android = ua.match(/(Android);?[\s\/]+([\d.]+)?/)
  var ipad = ua.match(/(iPad).*OS\s([\d_]+)/)
  var ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/)
  var iphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/)
  var microMsgReg = ua.match(/MicroMessenger/i)
  var androidPadReg = /;SYZJ_ANDROID_PAD/g.test(ua)
  var isFireFox = /(?:Firefox)/.test(ua)
  var isTablet = /(?:iPad|PlayBook)/.test(ua) || (android && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))

  device.ios = device.android = device.iphone = device.ipad = device.androidChrome = device.microMsg = device.androidPad = false

// Android
  if (android) {
    device.os = 'android'
    device.osVersion = android[2]
    device.android = true
    device.androidChrome = ua.toLowerCase().indexOf('chrome') >= 0
  }
  if (ipad || iphone || ipod) {
    device.os = 'ios'
    device.ios = true
  }
// iOS
  if (iphone && !ipod) {
    device.osVersion = iphone[2].replace(/_/g, '.')
    device.iphone = true
  }
  if (ipad) {
    device.osVersion = ipad[2].replace(/_/g, '.')
    device.ipad = true
  }
  if (ipod) {
    device.osVersion = ipod[3] ? ipod[3].replace(/_/g, '.') : null
    device.iphone = true
  }
  // 微信内置浏览器
  if(microMsgReg) {
    device.microMsg = true
  }
  // 安卓一体机
  if (androidPadReg || isTablet) {
    device.androidPad = true
  }
  return device
})()

export default device
