<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar title="选择商品" left-arrow right-text="新增商品" @click-right="addGoods"></nav-bar>
    </template>
    <scroll-view height="fullView" ref="scroll" pullup @scrollToEnd="loadMore()">
      <div class="font-15 text-color gray pl-15 pt-10 tip-box">请选择要分享的商品</div>
      <div class="list-box pl-15 ds-flex align-center"
           :key="index"
           v-for="(item, index) in listData"
           @click="toDetail(item)"
      >
        <van-image
          width="40"
          height="40"
          v-if="item.picture"
          class="goodsPic"
          fit="cover"
          :src="imgFullFix(item.picture, 'small')"
        />
        <div class="space-img ds-flex align-center justify-center"
             v-else
        >
          <i class="iconfont2 icon-CombinedShape1"></i>
        </div>
        <div class="list-right ml-15 full-width pr-15"
             v-if="item!== null"
        >
          <div class="ds-flex space-between align-center mb-5">
            <span class="good-name font-14">{{ item.name }}</span>
            <span class="good-count font-small">{{ item.quantity }}</span>
          </div>
          <div class=" font-13" style="position:relative;">
            <price
              :price="item.oriPrice ? item.oriPrice : item.price"
              :needSymbol="true"
              :thousands="false"
              class="good-name text-color orange pr-25"
            />
          </div>
        </div>
      </div>
      <scroll-view-loading :isFinished="isFinished" />
    </scroll-view>
    <van-popup v-model="showPopUp" position="bottom" :style="{ height: '50%' }" :safe-area-inset-bottom="true">
      <div class="PoperContentView">
        <div class="top-title font-14 ds-flex space-between van-hairline--bottom  pl-15 pr-15">
          <span>颜色尺码</span>
          <span class="text-color orange" @click="showPopUp = false">取消</span>
        </div>
        <div v-if="extendData.length">
          <p class="total-title pl-15 pr-15 van-hairline--bottom bg-color gray">共{{ extendData.length || 0 }}种商品</p>
          <div class="list-bo">
            <div class="top-title font-14 ds-flex space-between van-hairline--bottom pl-15 pr-15"
                 :key="index"
                 v-for="(item, index) in extendData"
                 @click="toExtendDetail(item)"
            >
              <span v-if="item.skuRelations.length === 1">{{ item.skuRelations[0].gaVName }} &nbsp;&nbsp;<price
                :price="item.oriPrice ? item.oriPrice : item.price"
                :needSymbol="true"
                :thousands="false"
                class="text-color orange"
              /></span>
              <span v-else>{{ item.skuRelations[0].gaVName }} {{ item.skuRelations[1].gaVName }}&nbsp;&nbsp;<price
                :price="item.oriPrice ? item.oriPrice : item.price"
                :needSymbol="true"
                :thousands="false"
                class="text-color orange"
              /></span>
              <span class="">×{{ item.quantity }}</span>
            </div>
          </div>
        </div>
        <van-empty description="暂无数据" v-else />
      </div>
    </van-popup>
  </page-view>
</template>
<script>
import PageView from '@/layouts/PageView'
import ScrollView from '@/components/scroll-view'
import ScrollViewLoading from '@/components/scroll-view-loading'
import Price from '@/components/price'
import NavBar from '@/components/nav-bar'
import { imgFullFix } from '@/utils/img'
import { Image, Popup, Empty } from 'vant'
import miniProgramModel from '../../model/miniprogram/index'
export default {
  name: '',
  mixins: [],
  components: { NavBar, Price, ScrollViewLoading, ScrollView, PageView, [Image.name]: Image, [Popup.name]: Popup, [Empty.name]: Empty },
  props: {},
  data () {
    return {
      extendData: [],
      listData: [],
      baseData: {},
      loading: false,
      showPopUp: false,
      showTip: false,
      isFinished: false,
      empty: false,
      totalMoney: null,
      pageConfig: {
        pageIndex: 0,
        pageSize: 20,
        totalCount: 0,
        currentPage: 1
      },
      goodsId: null
    }
  },
  watch: {},
  async created () {
    this.loadGoodsList()
  },
  methods: {
    addGoods () {
      this.$router.push({ path: `/miniprogram-goodsshare`, query: { isAdd: true } })
    },
    toExtendDetail (data) {
      const extendInfo = {}
      extendInfo.gsId = data.skuId
      extendInfo.gsPrice = data.oriPrice ? data.oriPrice : data.price
      extendInfo.Attributes = data.skuRelations
      extendInfo.goodsId = this.goodsId
      extendInfo.gsQuantity = data.quantity
      const Obj = JSON.stringify(extendInfo)
      this.$router.push('/miniprogram-goodsshare/?goodsInfo=' + Obj)
    },
    showCustomPopupClick () {
      this.showPopUp = true
    },
    async toDetail (item) {
      this.goodsId = item.id
      if (item.isExtend === 0) {
        this.$router.push({ path: `/miniprogram-goodsshare`, query: { goodsId: item.id } })
        return
      }
      // let params = { gid: item.id }
      // const { Data } = await miniProgramModel.getGoodsExtendInfo(params)
      // this.extendData = Data
      this.extendData = item.skus
      this.showCustomPopupClick()
      // this.showCustomPopupClick()
    },
    imgFullFix,
    // 下拉加载事件
    async loadMore () {
      if (this.isFinished || this.loading) return
      this.loading = true
      await this.loadGoodsList()
      this.loading = false
    },
    async loadGoodsList (isNewPage = false) {
      if (isNewPage) {
        this.listData = []
        this.pageConfig.pageIndex = 0
        this.pageConfig.totalCount = 0
        this.noMore = false
        this.empty = false
      }
      let start = (this.pageConfig.currentPage - 1) * this.pageConfig.pageSize
      start = start < 0 ? 0 : start
      // let limit = this.pageConfig.pageSize
      const params = {
        start: start,
        pageSize: this.pageConfig.pageSize
      }
      const data = await miniProgramModel.getGoodsList(params)

      if (data.items === null) {
        data.items = []
      }
      this.listData = this.listData.concat(data.items)
      // this.listData.length = 0
      this.pageConfig.currentPage += 1
      this.pageConfig.totalCount = data.totalCounts
      this.noMore = data.items.length < this.pageConfig.pageSize
      this.empty = (this.pageConfig.pageIndex === 0 && !data.items.length)
      this.isFinished = this.empty
      // throw Error('加载推荐用户列表失败')
      // 数据加载完成之后要进行重新计算当前宽度
      this.$nextTick(() => {
        this.$refs.scroll.refresh()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
<style lang="scss">
.goods-list-content .c-navbar .right {
  font-size: 14px !important;
}
</style>
