/**
  * 基于Canvas进行图片的压缩
  * @param {Object} file file文件对象
  * @param {Object} obj
  * 第二个入参  可以直接是一个数字，表示图片大小，默认100kb (100000 byte)
            也可以是一个小数，表示图片压缩比例，例如 .8 表示 #分辨率# 压缩为原来的0.8倍
            也可以是一个对象，可以包含size, width, height, fileType，其中：
                size可以是图片大小，也可以是压缩比例
                width是压缩后的图片宽度，默认自动按比例缩小
                height是压缩后的图片高度，默认自动按比例缩小
                fileType是压缩后的图片类型，可以是png或者jpg，不填则与原文件相同
                qualityArgument是压缩后的图片质量，针对jpg图片，默认0.8中画质。0.6为低画质，0.9～1为高画质
  */
export default function compressImg (file, obj = {}) {
  return new Promise((resolve, reject) => {
    let {
      size = 800000,
      width,
      height,
      fileType,
      // eslint-disable-next-line prefer-const
      qualityArgument = 0.8
    } = obj

    if (typeof obj === 'number') {
      size = obj
    }
    if (file && file.size) {
      // 不需要压缩
      if (size && file.size <= size) {
        resolve(file)
        return
      }
    } else {
      reject(new Error('文件参数错误，请确认是否传入了文件'))
      return
    }

    if (!/(jpg|jpeg|png)$/.test(file.type)) {
      reject(new Error('文件格式不是jpg或者png，请确认文件格式'))
      return
    }
    fileType = fileType || file.type
    switch (fileType) {
      case 'jpg':
      case 'jpeg':
      case 'image/jpeg':
        fileType = 'image/jpeg'
        break
      case 'png':
      case 'image/png':
        fileType = 'image/png'
        break
      default:
        reject(new Error('不支持的文件格式'))
        return
    }
    // canvas检测。canvas用来压缩图片
    const canvas = document.createElement('canvas')
    if (!canvas || !canvas.getContext) {
      reject(new Error('浏览器不支持canvas'))
      return
    }
    const context = canvas.getContext('2d')

    // FileReader检测。FileReader用来转base64
    if (!window.FileReader) {
      reject(new Error('浏览器不支持FileReader'))
      return
    }
    const reader = new FileReader()
    const img = new Image()
    reader.readAsDataURL(file)

    reader.onload = function (e) {
      // e.target.result就是图片base64
      img.src = e.target.result
    }
    img.onload = function () {
      const originWidth = img.width
      const originHeight = img.height
      if (width && height) {
        if (width > originWidth && height > originHeight) {
          // 原始分辨率比设定的分辨率小，不需要压缩
          resolve(file)
          return
        }
      } else if (width) {
        if (width > originWidth) {
          // 原始分辨率比设定的分辨率小，不需要压缩
          resolve(file)
          return
        }
        height = originHeight * width / originWidth
      } else if (height) {
        if (height > originHeight) {
          // 原始分辨率比设定的分辨率小，不需要压缩
          resolve(file)
          return
        }
        width = originWidth * height / originHeight
      } else {
        const ratio = (size > 0 && size < 1) ? size : 0.9

        width = (originWidth * ratio) | 0
        height = (originHeight * ratio) | 0
      }
      canvas.width = width
      canvas.height = height
      context.drawImage(img, 0, 0, width, height)
      canvas.toBlob(function (blob) {
        if (size && size > 1) {
          if (blob.size <= size) {
            resolve(blob)
          } else {
            compressImg(blob, obj).then((newBlob) => {
              resolve(newBlob)
            })
          }
        } else {
          resolve(blob)
        }
      }, fileType, qualityArgument)
    }
  })
}
