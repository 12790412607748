// 小程序模块路由
// 快递
import Express from '@/views/express/index'
import minProgramGoodslist from '@/views/min-program-goodslist'
import minProgramVerify from '@/views/mini-program-verify'
import minProgramVerifyDetail from '@/views/mini-program-verify-detail'
// 商品分享浏览记录
import GoodsShareCv from '@/views/miniprogram-goodsshare/cv-history/index'
import MiniprogramGoodsShare from '@/views/miniprogram-goodsshare/index'

export default [
  {
    name: 'miniProgramIndex',
    path: '/mini-program/index',
    component: () => import('@/views/mini-program-summary')
  },
  {
    name: 'minProgramVerify', // 小程序开通审核流程
    path: '/min-program-verify',
    component: minProgramVerify
  },
  {
    name: 'minProgramGoodslist', // 小程序商品列表
    path: '/min-program-goodslist',
    component: minProgramGoodslist
  },
  {
    name: 'minProgramVerifyDetail',
    path: '/min-program-verify-detail',
    component: minProgramVerifyDetail
  },
  {
    path: '/miniprogram-goodsshare',
    component: MiniprogramGoodsShare,
    meta: {
      index: 1
    }
  },
  {
    path: '/mini-program-old-audit',
    component: () => import('@/views/mini-program-iframe-audit')
  },
  {
    path: '/attestminiprogram',
    component: () => import('@/views/attest-miniprogram'),
    meta: {
      index: 1
    }
  },
  {
    path: '/goodShare-cv',
    component: GoodsShareCv,
    meta: {
      index: 1
    }
  },
  {
    path: '/express',
    component: Express,
    meta: {
      index: 1
    }
  }
]
