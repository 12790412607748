<template>
  <page-view bgColor="#faf7fa">
    <!-- <c-page class="goods-containner"> -->
    <template v-slot:nav>
      <nav-bar title="浏览记录" left-arrow></nav-bar>
    </template>
    <scroll-view height="fullView" ref="scroll" pullup style="background-color:#faf7fa;">
      <div class="cv-box">
        <div class="cv-box__header">
          <div class="cv-box__info van-hairline--bottom">
            <img :src="this.memberDetailInfo.goodsPic" alt="" class="goodsPic">
            <span class="goods-box">
              <span>{{ this.memberDetailInfo.goodsName }}</span>
              <price
                :price="this.memberDetailInfo.onlinePrice"
                :needSymbol="true"
                :thousands="false"
                style="color:#FF4949"
              />
            </span>
          </div>
          <div class="cv-info">
            <span>
              <span>234</span>
              <span>浏览顾客</span>
            </span>
            <span>
              <span>234</span>
              <span>浏览次数</span>
            </span>
            <span>
              <span>￥234</span>
              <span>合计成交</span>
            </span>
          </div>
        </div>
        <div class="cv-box__allinfo">
          <!-- <span>共23条浏览记录</span>
          <span>预计可成交3单</span> -->
        </div>

        <div class="cv-box__list">
          <div class="item van-hairline--bottom">
            <span class="avatar"></span>
            <span class="goods-name">红中</span>
            <span class="phone">151 2102 6907</span>
            <span class="times">13次</span>
            <span class="time">2020-02-19</span>
          </div>
          <div class="item van-hairline--bottom">
            <span class="avatar"></span>
            <span class="goods-name">红中</span>
            <span class="phone">151 2102 6907</span>
            <span class="times">13次</span>
            <span class="time">2020-02-19</span>
          </div>
          <div class="item van-hairline--bottom">
            <span class="avatar"></span>
            <span class="goods-name">红中</span>
            <span class="phone">151 2102 6907</span>
            <span class="times">13次</span>
            <span class="time">2020-02-19</span>
          </div>
          <div class="item van-hairline--bottom">
            <span class="avatar"></span>
            <span class="goods-name">红中</span>
            <span class="phone">151 2102 6907</span>
            <span class="times">13次</span>
            <span class="time">2020-02-19</span>
          </div>
          <div class="item van-hairline--bottom">
            <span class="avatar"></span>
            <span class="goods-name">红中</span>
            <span class="phone">151 2102 6907</span>
            <span class="times">13次</span>
            <span class="time">2020-02-19</span>
          </div>
          <div class="item van-hairline--bottom">
            <span class="avatar"></span>
            <span class="goods-name">红中</span>
            <span class="phone">151 2102 6907</span>
            <span class="times">13次</span>
            <span class="time">2020-02-19</span>
          </div>
        </div>

        <div class="cv-box__bottom">
          <span style="color:#999999;" class="tips"><span style="color:#FF4949;">* </span>预览10次以上的商品，一键发送短信提醒</span>
          <van-button color="#FF6A3C" block class="message-notice" @click="openNotice">短信提醒</van-button>
        </div>
      </div>
    </scroll-view>
    <van-dialog v-model="noticeModel" show-cancel-button confirmButtonColor="#FF6A3C" className="notice-dialog" @confirm="confrimMessage">
      <div class="notice-area">
        <van-field
          v-model="message"
          rows="2"
          autosize
          type="textarea"
          maxlength="50"
          placeholder=""
          show-word-limit
        />
      </div>
    </van-dialog>
    <!-- </c-page> -->
  </page-view>
</template>
<style lang="scss" scoped>
@import './index.scss';
</style>
<script>
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import Price from '@/components/price'
import { imgFullFix } from '@/utils/img'
import { Dialog, Button, Field } from 'vant'
import ScrollView from '@/components/scroll-view'
export default {
  data () {
    return {
      memberDetailInfo: {},
      noticeModel: false,
      message: '尊敬的XX先生/女士，您在化妆精品店心仪的商品等你很久了～快来带走它吧！'
    }
  },
  components: { ScrollView, NavBar, PageView, [Dialog.Component.name]: Dialog.Component, Price, [Button.name]: Button, [Field.name]: Field },
  created () {
  },
  mounted () {
    this.memberDetailInfo = this.$route.query
  },
  beforeDestroy () {
  },
  methods: {
    imgFullFix,
    openNotice () {
      this.noticeModel = true
    },
    confrimMessage () {
      // this.$toast('123')
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
</style>
