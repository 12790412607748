<template>
  <div class="avatar" :class="['avatar--' + size + ' avatar--' + shape + ' avatar--'+ theme, {'avatar--border': border} ]">
    <van-image v-if="avatarImage" fit="cover" lazy-load :src="avatarImage" />
    <div v-else>{{ showName }}</div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Image, Lazyload } from 'vant'
Vue.use(Lazyload)
export default {
  name: 'Avatar',
  mixins: [],
  components: { [Image.name]: Image },
  props: {
    size: {
      type: String,
      default: 'normal' // large  normal  size
    },
    shape: {
      type: String,
      default: 'circle' // circle  square
    },
    theme: {
      type: String,
      default: 'white' // white black
    },
    avatarImage: { // 头像地址
      type: String,
      default: ''
    },
    avatarName: { // 头像名称
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    showName () {
      if (this.avatarName) {
        return this._truncated(this.avatarName, 1)
      }
      return ''
    }
  },
  watch: {},
  created () { },
  mounted () { },
  destroyed () { },
  methods: {
    /**
     * 截取字符
     */
    _truncated (str, num) {
      let s = ''
      for (const v of str) {
        s += v
        num--
        if (num <= 0) {
          break
        }
      }
      return s
    }
  }
}
</script>
<style lang="less" scoped>
.avatar {
  position: relative;
  text-align: center;
  overflow: hidden;
  display: inline-block;
  &--large {
    width: 40px;
    height: 40px;
    font-size: 18px;
    line-height: 40px;
  }
  &--normal {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
  &--small {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }

  &--circle {
    border-radius: 50%;
  }

  &--square {
    border-radius:2px;
  }

  &--white {
    color: #999;
    background:linear-gradient(180deg,rgba(250,250,250,1) 0%,rgba(233,233,233,1) 100%);
  }

  &--border {
     &::after {
      position: absolute;
      box-sizing: border-box;
      content: " ";
      pointer-events: none;
      top: -50%;
      right: -50%;
      bottom: -50%;
      left: -50%;
      border: 1PX solid #ddd;
      border-radius: 4PX;
      transform: scale(0.5);
    }
  }

  &--black {
    color:#fff;
    background: #697b93;
  }
}
</style>
