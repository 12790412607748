import { apiUrl } from '@/config/net'
import api from '@/tools/api-tools'

class FormData {
  constructor () {
    this.formData = {
      userName: null, // 员工姓名
      userAccount: null, // 登录账号
      password: null, // 密码
      positionId: null, // 职位
      permissionIds: null // 员工权限
    }
  }

  _dataProcess ({ userId = '', message = '', code = false } = {}) {
    return {
      userId,
      message,
      code
    }
  }

  // 获取数据
  postUserInfo (postData) {
    return api.bpost(`https:${apiUrl}/mobile/passport/new`, postData).then(async res => {
      const userId = res.data.data
      const message = res.data.message
      const response = this._dataProcess({ userId, message })
      if (!userId) return response
      const updateStatus = await this.updatePermission(userId)
      response.code = updateStatus.code === 0
      response.message = updateStatus.message
      return response
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.error(err)
    })
  }

  // 更新权限
  updatePermission (userId) {
    const permissionData = {
      positionId: this.formData.positionId,
      permissionIds: this.formData.permissionIds
    }
    const url = `https:${apiUrl}/mobile/security/user/${userId}/position-permissions`
    return api.bpost(url, permissionData).then(res => {
      const data = res.data || {}
      return data
    })
  }

  // 330635
  // 更新店员的权限
  static loadUserPermissionsIds (userId) {
    const url = `https:${apiUrl}/mobile/security/user/${userId}/user-position-permissions`
    return api.bget(url).then(res => {
      const data = res.data.data || {}
      return { permissionIds: data.permissionIds || [], positionId: data.positionId }
    })
  }

  // 提交员工信息
  submit () {
    const userBaseData = {
      id: this.formData.id,
      account: this.formData.userAccount,
      name: this.formData.userName,
      password: this.formData.password,
      passwordRepeat: this.formData.password
    }
    // 提交用户基本信息
    return this.postUserInfo(userBaseData)
  }
}

export default FormData
