<template>
  <div class="main unselectable">
    <nav
      class="nav"
      v-if="$slots.nav"
      :class="[isShowNavBar ? '' : 'nav--none']"
    >
      <slot name="nav"></slot>
    </nav>
    <section class="view" :class="viewClass" :style="styles">
      <slot></slot>
    </section>
    <footer class="footer" :style="styles" v-if="$slots.footer">
      <slot name="footer"></slot>
    </footer>
    <!-- 此占位内容用于放置一些弹出层的东西，否可能会解析到view这个标签下 -->
    <slot name="other" v-if="$slots.other"></slot>
    <!-- 子路由 -->
    <div class="routerView" v-if="$slots.routerView">
      <transition name="van-slide-right" mode="out-in">
        <slot name="routerView"></slot>
      </transition>
    </div>
  </div>
</template>
<script>
import device from '@/tools/device'
export default {
  name: 'PageView',
  mixins: [],
  components: {},
  props: {
    bgColor: {
      type: String,
      default: '#fff'
    }
  },
  data () {
    return {}
  },
  computed: {
    styles () {
      return { backgroundColor: this.bgColor }
    },
    viewClass () {
      // 容器视图
      return {
        'view--has-nav-bar': this.isShowNavBar,
        'view--has-footer': this.isShowFooter,
        'view--has-nav-bar-footer': this.isShowNavBar && this.isShowFooter
      }
    },
    isShowNavBar () {
      return this.$slots.nav && device.android
    },
    isShowFooter () {
      return this.$slots.footer
    }
  },
  watch: {},
  created () { },
  mounted () {
  },
  destroyed () { },
  methods: {}
}
</script>
<style lang="less" scoped>
.main {
  position: relative;
  min-height: 100vh;
  z-index: 1;
}

.nav {
  position: fixed;
  width: 100%;
  height: 45px;
  top: 0;
  left: 0;
  z-index: 10;
  &--none {
    display: none;
  }
}

.view {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  &--has-nav-bar {
    top: 45px;
    bottom: 0;
    height: calc(100vh - 45px);
  }
  &--has-footer {
    top: 0;
    bottom: 70px;
    height: calc(100vh - 70px);
  }
  &--has-nav-bar-footer {
    top: 45px;
    bottom: 70px;
    height: calc(100vh - 115px);
  }
}

.footer {
  position: fixed;
  height: 70px;
  width: 100%;
  bottom: 0;
  left: 0;
  // z-index: 10;
}

.routerView {
  position: relative;
  min-height: 100vh;
  z-index: 15;
}
</style>
