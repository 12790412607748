import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import './assets/scss/app.scss'
import './assets/scss/base/symbol.css'
import './assets/scss/base/symbol.js'
import './directive'
import './filter'
import router from './router'
import store from './store'

if (process.env.NODE_ENV !== 'development') {
  /* eslint-disable */
  window.$feInjectComplate = function () {
    window.$bridge = window.$fe_bridge
    window.$bridge_queue = window.$fe_bridge_queue
  }
  /* eslint-enable */
  require('app-bridge')
}

const bus = new Vue()
Object.defineProperty(Vue.prototype, '$bus', {
  get () {
    return bus
  }
})

Vue.config.productionTip = false
/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
