let api = null
let baseUrl = ''
let imgUrl = 'http://img.laoban100.com'
const actionUrl = 'https://v1.api.upyun.com/img-i200/'

const uploadConfig = {
  config: {},
  time: 0
}
// 设置api实例
const setApi = function (apiIns, apiUrl) {
  api = apiIns
  baseUrl = apiUrl
}

// 初始化upyun配置
const initUpyun = function () {
  if (!api) return Promise.resolve({})
  return api.get(`${baseUrl}/cebcollection/uploadconfig`).then(res => {
    const data = res.data.data || {}
    imgUrl = data.imgHostUrl || 'http://img.laoban100.com'
    return {
      bucketName: 'img-i200',
      imgHostUrl: imgUrl,
      policy: data.policy,
      signature: data.signStr
    }
  }).catch(e => {
  })
}

// 获取初始化配置
const getUploadConfig = async function () {
  const limitTime = 3 * 60 * 1000
  if (new Date().getTime() - uploadConfig.time > limitTime) {
    uploadConfig.config = initUpyun()
    uploadConfig.time = new Date().getTime()
  }
  return uploadConfig.config
}

// 获取完整图片地址
const fullImgUrl = function (url) {
  if (!url) return ''
  if (/http(s*):\/\//.test(url)) {
    return url
  } else {
    url = url.replace(/^\//, '')
    return `${imgUrl}/${url}`
  }
}

export default {
  setApi,
  initUpyun,
  fullImgUrl,
  getUploadConfig,
  actionUrl
}
