<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar title="会员消息" left-arrow></nav-bar>
    </template>
    <scroll-view style="background-color:#f8f8f8;">
      <div class="message__box">
        <div class="message__detail">
          <span class="message__info">尊敬的杨燕女士，您今日在本店消费45.00元，累计积分129分，感谢您的关照！</span>
          <span class="time">2020-01-11 12:12:02</span>
        </div>
      </div>
    </scroll-view>
  </page-view>
</template>
<style lang="scss" scoped>
@import "../../../../src/assets/scss/base/_mixin";
@import "../../../../src/assets/scss/base/config";

.message__box{
    width:px2rem(700px);
    margin:px2rem(30px) auto;
    .message__detail{
        margin:px2rem(25px) auto;
        background-color:white;
        padding:px2rem(25px);
        padding-bottom:px2rem(62px);
        .message__info{
            color:#666666;
            font-size:px2rem(26px);
            display:block;
        }
        .time{
            color:#999999;
            font-size:px2rem(22px);
            float:right;
        }
    }
}
</style>
<script>
import PageView from '@/layouts/PageView'
import ScrollView from '@/components/scroll-view'
import NavBar from '@/components/nav-bar'
export default {
  data () {
    return {
    }
  },
  components: { PageView, ScrollView, NavBar },
  created () {
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
  },
  computed: {
  },
  watch: {}
}
</script>
