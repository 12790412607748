<template>
  <page-view bgColor="#F7F4F8">
    <template v-slot:nav>
      <nav-bar title="发货" left-text="返回" left-arrow></nav-bar>
    </template>
    <div>
      <van-tabs v-model="active"
                :line-width="lineWidth"
                @click="changeTabs"
      >
        <van-tab title="快捷发货">
          <orderStatus v-if="!showSendBtn"
                       :orderInfo="orderInfo"
                       :orderId="orderId"
                       @orderCancel="orderCancel"
          ></orderStatus>
          <div style="background-color:white;"
               class="all-box"
          >
            <sender @showAddress="showAddress"
                    @senderInfo="senderInfo"
                    @hideboxSend="hideboxSend"
                    @showKeyBoard="showNumberKeyBoard"
                    :sendModel="sendModel"
            ></sender>
            <div class="border-box van-hairline--top"
                 v-if="hideBoxModel"
            ></div>
            <div class="border-box__full van-hairline--top"
                 v-else
            ></div>
            <div :class="[brand === 'huawei' ? 'huawei-box' : 'line-box']"
                 v-if="hideBoxModel"
            ></div>
            <receiver @showReceiverAddress="showReceiverAddress"
                      @receiverInfo="receiverInfo"
                      @hideboxReceive="hideboxReceive"
                      @showKeyBoard="showNumberKeyBoard"
                      :receiverModel="receiverModel"
            ></receiver>
          </div>

          <div class="express-info"
               v-if="showSendBtn"
          >
            <van-cell title="快递公司"
                      is-link
                      :value="expressCompanyValue"
                      @click="showExpressCompany = true"
            />
            <!-- <van-cell title="物品信息"
                        is-link
                        @click="showGoodsInfo = true"
                        style="position:relative;">
                <span class="goods-type">{{goodsTypeWeight}}</span>
              </van-cell> -->
            <van-cell title="取件时间"
                      is-link
                      value-class="getGoodsTime"
                      :value="getGoodsTime"
            />
            <van-cell title="备注">
              <input type="text"
                     placeholder="请输入备注信息"
                     class="remark"
                     v-model="remark"
              >
            </van-cell>
          </div>
          <van-checkbox v-model="sendNotify"
                        class="check-style van-checkbox__icon"
                        checked-color="#FF6A3C"
                        style="border-radius:0px;"
                        shape="square"
                        v-if="showSendBtn"
          ><span class="check-span">发送短信通知顾客</span></van-checkbox>
          <van-button type="primary"
                      class="order-btn"
                      round
                      @click="sureOrder"
                      v-if="showSendBtn"
          >发货</van-button>
        </van-tab>
        <van-tab title="快递单补录">
          <div class="send-content">
            <van-cell title="快递单号">
              <input type="text"
                     class="expressNumber-input"
                     style="border:none;"
                     placeholder="请输入快递单号"
                     v-model="ticketCode"
              >
              <span @click="invokeScanCamera"
                    slot="right-icon"
              >
                <i class="iconfont2 icon--saomiao saomiao"></i>
              </span>
            </van-cell>
            <van-cell title="快递公司">
              <input type="text"
                     class="expresscompany-input"
                     style="border:none;"
                     placeholder="请输入快递公司"
                     v-model="directShipmentValue"
              >
            </van-cell>
            <van-checkbox v-model="sendNotify"
                          class="dirt-style"
                          checked-color="#FF6A3C"
                          style="border-radius:0px;"
                          shape="square"
                          v-if="showSendBtn"
            >发送短信通知顾客</van-checkbox>
          </div>
          <van-button type="primary"
                      class="order-btn"
                      round
                      @click="sureOrder"
          >发货</van-button>
        </van-tab>

      </van-tabs>
    </div>
    <!-- </c-content> -->
    <!-- 数字键盘 -->
    <!-- 地址选择 -->
    <van-popup v-model="show"
               position="bottom"
               :style="{ height: '40%' }"
    >
      <van-area :area-list="areaList"
                @confirm="confirmAddress"
                show-toolbar
                title="请选择省市区"
      />
    </van-popup>
    <!-- 快递公司 -->
    <van-popup v-model="showExpressCompany"
               position="bottom"
               :style="{ height: '35%' }"
    >
      <van-picker :columns="columns"
                  :default-index="1"
                  @cancel="showExpressCompany = false"
                  @confirm="onConfirm"
                  show-toolbar
                  title="请选择快递公司"
      />
    </van-popup>

    <!-- </c-page> -->
  </page-view>
</template>
<script>
import Sender from './modules/Sender'
import NavBar from '@/components/nav-bar'
import PageView from '@/layouts/PageView'
import Receiver from './modules/Receiver'
import OrderStatus from './modules/OrderStatus'
import provinceList from './modules/area'
import dayjs from 'dayjs'
import { Popup, Picker, Tab, Tabs, Cell, CellGroup, Button, Area } from 'vant'
import ExpressModel from '@/model/express/index'
import device from '@/tools/device'
export default {
  name: '',
  mixins: [],
  components: { Sender, Receiver, OrderStatus, [NavBar.name]: NavBar, [PageView.name]: PageView, [Popup.name]: Popup, [Picker.name]: Picker, [Tab.name]: Tab, [Tabs.name]: Tabs, [Cell.name]: Cell, [CellGroup.name]: CellGroup, [Button.name]: Button, [Area.name]: Area },
  props: {},
  data () {
    return {
      active: 0,
      show: false,
      hideBoxModel: false,
      senderInfoData: {}, // 寄件人信息
      receiverInfoData: {}, // 收件人信息
      showGoodsInfo: false,
      expressCompanyValue: '京东物流',
      directShipmentValue: '',
      showExpressCompany: false,
      value: '',
      showReceiver: false,
      selectTypeList: [],
      columns: [],
      goodsTypeWeight: '请选择',
      textWarning: false,
      otherWarning: false,
      otherGoodsName: '',
      weight: 1,
      time: null,
      remark: '',
      sendNotify: true,
      tabValue: 0,
      getGoodsTime: '两小时上门',
      expressCompanyObj: {},
      sendModel: {},
      receiverModel: {},
      ticketCode: null,
      showSendBtn: true,
      orderInfo: {},
      orderId: 0,
      brand: 'default',
      goodsType: [
        {
          value: '文件',
          checked: false
        },
        {
          value: '数码产品',
          checked: false
        },
        {
          value: '生活用品',
          checked: false
        },
        {
          value: '服饰',
          checked: false
        },
        {
          value: '食品',
          checked: false
        },
        {
          value: '其他',
          checked: false
        }
      ],
      hideReceive: true,
      hideSend: true
    }
  },
  watch: {
    showGoodsInfo (value) {
      if (value) {
        if (!this.goodsTypeWeight) {
          this.init()
        }
      }
    }
  },
  async created () {
  },
  mounted () {
    const brand = this.judgeBrand(navigator.userAgent.toLowerCase())
    this.brand = brand
    this.orderId = this.$route.query.orderId
    this._getExpressCompany() // 获取快递公司
    this._getSenderList() // 获取发货人列表
    this._getOrderInfo()
    this._getReceiverInfo()
    // this.time = setTimeout(() => {
    //   if (this.receiverModel.name && this.sendModel.name) this.hideBoxModel = true
    // }, 800)
  },
  methods: {
    dayjs,
    judgeBrand (sUserAgent) {
      const isIphone = sUserAgent.indexOf('iphone') > -1
      const isHuawei = sUserAgent.indexOf('huawei') > -1
      const isHonor = sUserAgent.indexOf('honor') > -1
      if (isIphone) {
        return 'iphone'
      } else if (isHuawei || isHonor) {
        return 'huawei'
      } else {
        return 'default'
      }
    },
    onInput (value) {
      if (this.showType === 'send') this.sendModel.telephone += String(value)
      if (this.showType === 'receive') this.receiverModel.telephone += String(value)
    },
    showNumberKeyBoard (value) {
      this.showType = value
      this.showKeyBoard = true
    },
    onDelete () {
      if (this.showType === 'send') this.sendModel.telephone = this.sendModel.telephone.slice(0, this.sendModel.telephone.length - 1)
      if (this.showType === 'receive') this.receiverModel.telephone = this.receiverModel.telephone.slice(0, this.receiverModel.telephone.length - 1)
    },
    async _getReceiverInfo () {
      const data = await ExpressModel.getEeceiverInfo(this.orderId)
      const receiverModel = {
        name: data.contactName,
        address: data.address,
        telephone: data.phone,
        province: data.province + ' ' + data.city + ' ' + data.country
      }
      this.receiverModel = receiverModel
      if (this.receiverModel.name !== '' && this.sendModel.name !== '') this.hideBoxModel = true
    },
    hideboxSend (value) {
      clearTimeout(this.time)
      this.hideSend = !value
      if (this.hideSend && this.hideReceive) {
        this.hideBoxModel = true
        return
      }
      this.hideBoxModel = false
      // if (value) {
      //   this.hideBoxModel = false
      //   return
      // }
      // this.hideBoxModel = true
    },
    hideboxReceive (value) {
      clearTimeout(this.time)
      this.hideReceive = !value
      if (this.hideSend && this.hideReceive) {
        this.hideBoxModel = true
        return
      }
      this.hideBoxModel = false
    },
    async _getOrderInfo () {
      const data = await ExpressModel.getSendInfo(this.orderId)
      if (data && data.currentStatus !== 11 && data.currentStatus !== 9) {
        this.orderInfo = data
        this.showSendBtn = false
        const sendModel = {
          name: data.senderName,
          telephone: data.senderPhone,
          address: data.senderAddress,
          province: `${data.senderProvince} ${data.senderCity} ${data.senderCounty}`
        }
        const receiverModel = {
          name: data.receiverName,
          telephone: data.receiverPhone,
          address: data.receiverAddress,
          province: `${data.receiverProvince} ${data.receiverCity} ${data.receiverCounty}`
        }
        this.sendModel = sendModel
        this.receiverModel = receiverModel
        return
      }
      this.showSendBtn = true
    },
    orderCancel (value) {
      if (value) {
        this._getSenderList() // 获取发货人列表
        this._getOrderInfo()
        this._getReceiverInfo()
        this.$toast('取消成功')
      }
    },
    chaifen (value) {
      let arr = []
      const data = {
        goodsType: '',
        weight: ''
      }
      arr = value.split(' ')
      arr.forEach(item => {
        if (item.indexOf('kg') < 0) data.goodsType += (item + ' ')
      })
      data.weight = arr[arr.length - 1]
      if (data.weight.indexOf('kg') > -1) data.weight = data.weight.slice(0, 1)
      return data
    },
    chaifenAddress (value) {
      let arr = []
      const data = {
        province: '',
        city: '',
        county: ''
      }
      arr = value.split(' ')
      data.province = arr[0]
      data.city = arr[1]
      data.county = arr[2]
      return data
    },
    // 唤醒扫码，获取码信息
    invokeScanCamera () {
      this.ticketCode = ''
      window.$bridge.device.scanCamera((value) => {
        if (device.ios) {
          const ticketCode = JSON.parse(decodeURI(value)).barcode
          this.ticketCode = ticketCode
          return
        }
        this.ticketCode = value.barcode
      })
    },
    // 验证手机号
    testPhone (value) {
      return /^1\d{10}$/.test(value)
    },
    async sureOrder () {
      if (this.tabValue === 1) {
        const params = { orderId: this.orderId, expressCode: this.ticketCode || '', expressName: this.directShipmentValue || '', sendNotify: this.sendNotify }
        const data = await ExpressModel.dirctSend(params)
        if (data) this.$toast('成功发货')
        return
      }
      if (this._checkStatus()) {
        let companyKey = ''
        Object.keys(this.expressCompanyObj).forEach(key => {
          if (this.expressCompanyObj[key] === this.expressCompanyValue) companyKey = key
        })
        // let data = this.chaifen(this.goodsTypeWeight)
        const senderData = this.chaifenAddress(this.sendModel.province)
        const receiverData = this.chaifenAddress(this.receiverModel.province)
        const params = {
          OrderId: this.orderId,
          ExpressCompany: companyKey, // 快递公司
          ReceiverName: this.receiverInfoData.name, // receiver name
          ReceiverPhone: this.receiverInfoData.telephone, // receiver phone
          ReceiverProvince: receiverData.province,
          ReceiverCity: receiverData.city,
          ReceiverCounty: receiverData.county,
          ReceiverAddress: this.receiverInfoData.address,
          SenderName: this.senderInfoData.name,
          SenderPhone: this.senderInfoData.telephone,
          SenderCounty: senderData.county,
          SenderProvince: senderData.province,
          SenderCity: senderData.city,
          SenderAddress: this.senderInfoData.address,
          Cargo: '文件',
          Weight: 1,
          Remark: this.remark,
          Payment: null,
          DayType: '今天',
          sendNotify: this.sendNotify,
          PickupStartTime: `${dayjs().hour()}:${dayjs(Date.now()).minute() < 10 ? '0' + dayjs(Date.now()).minute() : dayjs(Date.now()).minute()}`,
          PickupEndTime: `${dayjs(Date.now() + 2 * 60 * 60 * 1000).hour()}:${dayjs(Date.now() + 2 * 60 * 60 * 1000).minute() < 10 ? '0' + dayjs(Date.now() + 2 * 60 * 60 * 1000).minute() : dayjs(Date.now() + 2 * 60 * 60 * 1000).minute()}` // 昨天
        }
        const resData = await ExpressModel.goToSend(params)
        if (resData.status) {
          this._getOrderInfo()
          this.$toast('下单成功')
          return
        }
        this.$toast(resData.message)
      }
    },
    _checkStatus () {
      if (!this.senderInfoData.name) {
        this.$toast('请填写寄件人姓名')
        return
      }
      if (!this.senderInfoData.province) {
        this.$toast('请补全寄件人省市区')
        return
      }
      if (!this.senderInfoData.address) {
        this.$toast('请填写寄件人地址')
        return
      }
      if (!this.senderInfoData.telephone) {
        this.$toast('请填写寄件人联系方式')
        return
      }
      if (!this.receiverInfoData.telephone) {
        this.$toast('请填写收件人联系方式')
        return
      }
      if (!this.receiverInfoData.address) {
        this.$toast('请填写收件人地址')
        return
      }
      if (!this.receiverInfoData.name) {
        this.$toast('请补全收件人姓名')
        return
      }
      if (!this.receiverModel.province) {
        this.$toast('请补全收件人省市区')
        return
      }
      if (!this.expressCompanyValue) {
        this.$toast('请选择快递公司')
        return
      }
      if (!this.goodsTypeWeight) {
        this.$toast('请补充商品信息')
        return
      }
      if (!this.testPhone(this.senderInfoData.telephone)) {
        this.$toast('请输入正确的手机号')
        return
      }
      if (!this.testPhone(this.receiverInfoData.telephone)) {
        this.$toast('请输入正确的手机号')
        return
      }
      return true
    },
    senderInfo (value) { // 接受 寄件人信息
      this.senderInfoData = value
    },
    receiverInfo (value) { // 收件人信息
      this.receiverInfoData = value
    },
    changeTabs (value) {
      this.tabValue = value
    },
    showAddress (value) {
      this.showReceiver = false
      if (value) this.show = true
    },
    showReceiverAddress (value) {
      this.showReceiver = true
      if (value) this.show = true
    },
    sureGoodsInfo () {
      if (!this.selectTypeList.length) {
        this.$toast('请至少选择一种物品类型！')
        return
      }
      if (this.selectTypeList.length && this.goodsType[5].checked) {
        if (!this.otherGoodsName) {
          this.$toast('请填写商品名称')
          return
        }
      }
      let goodsTypeWeight = ''
      this.selectTypeList.map(item => {
        if (item.value === '其他') {
          goodsTypeWeight = goodsTypeWeight + this.otherGoodsName + ' '
        } else {
          goodsTypeWeight += (item.value + ' ')
        }
      })
      this.goodsTypeWeight = goodsTypeWeight + `${this.weight}kg`
      this.showGoodsInfo = false
    },
    async _getExpressCompany () {
      const data = await ExpressModel.getExpressCompany()
      this.expressCompanyObj = data
      Object.keys(data).forEach((key) => {
        this.columns.push(`${data[key]}`)
      })
      this.columns.forEach((item, index) => {
        if (item === '顺丰速运') this.columns[index] = `<svg class="icon" aria-hidden="true"><use xlink:href="#icon-bianzu7"></use></svg> 顺丰速运`
        if (item === '京东物流') this.columns[index] = `<svg class="icon" aria-hidden="true"><use xlink:href="#icon-bianzu8"></use></svg> 京东物流`
        if (item === '德邦快递') this.columns[index] = `<svg class="icon" aria-hidden="true"><use xlink:href="#icon-debangkuaidi"></use></svg> 德邦快递`
      })
    },
    async _getSenderList () {
      const data = await ExpressModel.getSenderList()
      if (data && data.length) {
        const sendModel = {
          name: data[0].senderName,
          telephone: data[0].senderPhone,
          address: data[0].senderAddress,
          province: data[0].senderProvince + ' ' + data[0].senderCity + ' ' + data[0].senderCounty
        }
        this.sendModel = sendModel
        if (this.receiverModel.name && this.sendModel.name) this.hideBoxModel = true
        return
      }
      this.sendModel = {
        name: '',
        telephone: null,
        address: '',
        province: ''
      }
    },
    onAddrCancel () {
    },
    _init () {
      this.textWarning = false
      this.otherWarning = false
      this.selectTypeList = []
      this.goodsType.map(item => { item.checked = false })
    },
    selectGoodsType (value) {
      if (!value.checked && this.selectTypeList.length === 3) {
        this.$toast('托寄物类型最多只能选择三项')
        return
      }
      value.checked = !value.checked
      if (value.checked) this.selectTypeList.push(value)
      if (!value.checked) {
        this.selectTypeList.map((item, index) => {
          if (item.value === value.value) this.selectTypeList.splice(index, 1)
        })
      }
      if (value.value === '文件') this.textWarning = value.checked
      if (value.value === '其他') this.otherWarning = value.checked
    },
    confirmAddress (value) {
      const address = `${value[0].name} ${value[1].name} ${value[2].name}`
      if (!this.showReceiver) {
        // let sendModel = {
        //   province: address
        // }
        this.sendModel.province = address
      } else {
        // let receiverModel = {
        //   province: address
        // }
        this.receiverModel.province = address
      }
      this.show = false
    },
    onConfirm (value) {
      if (value.indexOf('顺丰速运') > -1) value = '顺丰速运'
      if (value.indexOf('京东物流') > -1) value = '京东物流'
      if (value.indexOf('德邦快递') > -1) value = '德邦快递'
      this.showExpressCompany = false
      if (this.tabValue === 0) this.expressCompanyValue = value
      if (this.tabValue === 1) this.directShipmentValue = value
    }
  },
  computed: {
    lineWidth () {
      return 80
    },
    areaList () {
      return provinceList
    }
  }
}
</script>
<style lang="scss">
@import './index.scss';
.icon-font-sf {
  color: red;
}
.check-span {
  color: #9b9b9b;
  font-size: px2rem(24px);
  display: inline-block;
  // margin-bottom: px2rem(8px);
}
.van-picker-column__item {
  font-size: px2rem(38px);
  font-weight: normal;
}
.van-picker-column__item--selected {
  font-weight: 500;
}
.van-tabs--line .van-tabs__wrap {
  height: 50px;
}
.van-tab {
  line-height: 50px;
  font-size: px2rem(30px);
}
</style>
