import api from '@/tools/api-tools'
import { apiUrl } from '@/config/net'

/**
 * 会员卡详情
 */
export default class MemberDetailMondel {
  /**
   * 会员卡详情
   */
  static getMemberDetail (memberId) {
    return api.get(`${apiUrl}/mobile/membersearch/MemberDetail?uid=${memberId}`).then(res => res.data)
  }

  /**
   * 该会员计次卡详情 http://webapi.laoban100.com/v0/mobile/member/timecardlist?uid=21772771
   */
  static getMemberCardTime (memberId) {
    return api.get(`${apiUrl}/mobile/member/timecardlist?uid=${memberId}`).then(res => res.data.data)
  }
}
